import React, { Component, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { iAxios } from '../../utils';
import queryString from 'query-string'
import { useDispatch } from 'react-redux';


const Login = (props) => {

    const [mode, setMode] = useState('initial');
    const { provider } = useParams()

    let [sp, setSearchParams] = useSearchParams()
    const navigate = useNavigate();

    useEffect(() => {
        const token = sp.get('access_token');
        const code = sp.get('code')

        if (!code) {
            return;
        }
        // iAxios.get(`/api/auth/${provider}/callback?access_token=${token}`).then(response => {
        //     console.log(response.data);
        //     localStorage.setItem('user', JSON.stringify(response.data.user));
        //     localStorage.setItem('access_token', response.data.jwt);
        //     document.location.href = `/member/xyz/CurrentSprint`;
        // }).catch(error => {
        //     console.error('Error during login:', error);
        //     alert('Authentication failed. Please try again.');
        // });

        const params = new URLSearchParams();
        params.append('code', sp.get('code'));
        params.append('state', sp.get('state'));
        iAxios({
            method: 'post',
            url: '/api/auth/gitlab',
            data: params
        }).then(resp => {
            const { data } = resp.data;
            localStorage.setItem('access_token', data.jwt);
            localStorage.setItem('user', JSON.stringify(data));
            navigate('/')
        }).catch(ex => {
            document.location.href = '/';
            console.error(ex);
        })

    }, [sp, provider])

    return (
        <div className="w3-white" style={{ background: "url(/bg.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', minHeight: '100vh' }}>
            <div className='w3-blue w3-padding-small '>
                <div className=''>
                    <h4 style={{ margin: '0px', lineHeight: '48px', fontSize: '42px' }}> <img src="/logo.png" style={{ height: '42px' }} alt="logo" /> ZCALO</h4>
                </div>
            </div>
            {mode === 'progress' && <span>Logging you in..</span>}
            {mode === 'initial' &&
                <div className='w3-row  w3-content w3-padding-64'>
                    <div className='w3-col m8  w3-padding   '>
                        <div className='w3-white w3-padding' style={{ minHeight: '300px' }}>
                            <h2>Scale your teams productivity to new heights</h2>
                            <div>Today is going to be the best day in your life..</div>
                        </div>
                    </div>
                    <div className='w3-col m4  w3-padding w3-center'>
                        <div className='w3-white w3-round w3-round  w3-padding-32'  >
                            <div>
                                <img src="/logo128.png" className='w3-image' alt="logo" />
                                <h4>ZCALO Dashboard</h4>
                            </div>
                            <a className="w3-button w3-round w3-black w3-margin-bottom" href="/auth/gitlab"> Login With Gitlab</a>
                            <br />
                            <a className="w3-button w3-hide w3-round w3-black" href="/api/connect/google"> Login With Google</a>
                        </div>
                    </div>
                </div>}
        </div>
    );

}


export const LoginHandler = () => {
    const at = localStorage.getItem('access_token');
    const loginUrl = `cgs-copilot://login/${at}`;

    useEffect(() => {
        if (!at) {
            return;
        }
        setTimeout(() => {
            document.location.href = loginUrl
        }, 500);

        setTimeout(() => {
            document.location.href = '/'
        }, 5000)
    }, [loginUrl, at]);
    return <div className='w3-center  w3-margin-64' style={{ padding: '60px' }}>
        <a href={at ? loginUrl : '/login'}>
            <h3>Click Here if the Copilot window doesnt open.</h3></a>
        <a href="/"><h3>Goto Home
        </h3></a>
        <div className='w3-tiny w3-margin-top'>This page will redirect to home in 5 seconds</div>
    </div>
}

export default Login