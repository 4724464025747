import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { formatSecs, workingDays, roundUpHour, filterIssues, getDiffClass, getMilestone } from '../../utils'
import IssueBlock from './IssueBlock.js';
import { connect } from 'react-redux';

const LeftRightText = ({ left, right }) => {
    return <div className="w3-row">
        <div className="w3-left cgs-status-text">{left}</div>
        <div className="w3-right cgs-status-text">{right}</div>
    </div>
}
export default class IssueGroup {

    constructor(uname, name, type = 'member') {
        this.name = name;
        this.type = type;
        this.uname = uname;
        this.issues = [];
        this.toPlanCount = 0;
        this.totalSpent = 0;
        this.totalEstimate = 0;
        this.totalSelfClosed = 0;
        this.closedEst = 0;
        this.filtered = 0;
        this.propgress = 0;
        this.projects = {};

    }

    addIssue(issue) {
        this.issues.push(issue);
        this.totalEstimate += issue.timeEstimate;
        this.totalSpent += issue.timeSpent;
        if (issue.timeEstimate === null) {
            this.toPlanCount++;
        }
        if (issue.state == 'closed') {
            this.closedEst += issue.timeEstimate;
        }

        if (issue.closedBy && issue.assignee && issue.closedBy.username == issue.assignee.username) {
            this.totalSelfClosed++;
        }
        if (issue.project) {
            this.projects[issue.project.key] = issue.timeEstimate;
            this.projects[issue.project.key] = issue.timeSpent;
        }
    }

    getStats(filtered = []) {

        const order = Object.keys(this.projects).map(el => ({
            est: this.projects[el],
            key: el.replace(/-_/g, ' ')
        })).sort((a, b) => b.est - a.est);

        const stats = {
            fname: this.name.split(' ').shift(),
            name: this.name,
            uname: this.uname,
            count: this.issues.length,
            tmProgress: 0,
            tmExpired: 0,
            tmTotal: 0,
            projects: order.map(el => el.key),
            vals: {
                completed: 0,
                spent: 0,
                estimate: 0,
                filtered: 0,
                closedEst: this.closedEst,
                days: 0,
                selfClosed: this.totalSelfClosed,
                toPlan: 0
            },
            perc: {
                completed: 0,
                spent: 0,
                estimate: 0,
                filtered: 0,
                days: 0,
                toPlan: 0
            }
        }
        const milestone = getMilestone();
        if (this.issues.length <= 0) {
            return stats;
        }
        const days = workingDays(
            milestone.start,
            milestone.end
        );
        const daysCompleted = workingDays(
            milestone.start,
            moment().add(-1, 'Days').format("YYYY-MM-DD")
        );
        this.filtered = filtered.length;
        const tmTotal = days * 8 * 60 * 60;
        const toLog = daysCompleted * 8 * 60 * 60;
        stats.tmTotal = roundUpHour(tmTotal);

        stats.tmProgress = (Math.min(days, daysCompleted) * 100 / days).toPrecision(3);
        stats.tmExpired = stats.tmProgress * 3600 * 8;


        stats.vals.estimate = roundUpHour(this.totalEstimate);
        stats.perc.estimate = (this.totalEstimate * 100 / tmTotal).toFixed(2);

        stats.vals.completed = this.issues.filter(item => item.state === 'closed').length;
        stats.perc.completed = (stats.vals.completed * 100 / this.issues.length).toPrecision(3);

        stats.vals.spent = roundUpHour(this.totalSpent);
        stats.perc.spent = (this.totalSpent * 100 / tmTotal).toPrecision(3);
        stats.vals.filter = filtered.length;
        stats.perc.filter = (filtered.length * 100 / this.issues.length).toPrecision(3);
        stats.vals.toPlan = this.toPlanCount;
        return stats;

    }
    renderView(filter) {
        let user = localStorage.getItem('user');

        if (!user || user == 'null') {
            user = {
                email: 'xxx'
            }
        } else {
            const info = JSON.parse(user);
            user = info.userInfo;
        }
        const milestone = getMilestone();
        const list = filterIssues(this.issues, filter).map((item) => {
            return <IssueBlock key={item.id} loadLinked={true} item={item}
                user={user}
                labelsToRemove={item.labelsToRemove} />
        });
        const stats = this.getStats(list, milestone);
        let cls = 'w3-green';
        if (this.type != 'member') {
            cls = 'w3-indigo';
        } else {
            cls = getDiffClass(stats.tmTotal - this.totalEstimate,
                [24 * 3600, 16 * 3600, 8 * 3600]
            );
        }

        this.filtered = list.length;

        const topSection = <div className="cgs-progress-text" key={filter}>
            <LeftRightText
                left={`E : ${formatSecs(stats.vals.estimate)}`}
                right={`${stats.perc.estimate}%`}
            />
            <LeftRightText
                left={`S : ${formatSecs(stats.vals.spent)}`}
                right={`${stats.perc.spent}%`}
            />
            
            <LeftRightText
                left={`Completed : ${stats.vals.completed}/${this.issues.length}`}
                right={`${stats.perc.completed}%`}
            />
            {
                (filter === 'completed' || filter === 'all') ? null : <LeftRightText
                    left={`${filter.toUpperCase()} Issues : ${list.length} / ${this.issues.length}`}
                    right={`${stats.perc.filter}%`}
                />
            }
        </div>

        const progressUI = <div className="cgs-i-progress">
            <div className="prog" style={{
                position: "absolute",
                height: "100%",
                borderRight: "solid 2px #5ae8db",
                background: "rgb(0,0,0,0.2)",
                width: stats.tmProgress + "%"
            }}>
            </div>
            <div className="prog" style={{ background: "#b70198", width: stats.perc.estimate + "%" }}>
            </div>
            <div className="prog" style={{ background: "#005aff", width: stats.perc.spent + "%" }}>
            </div>
            <div className="prog" style={{ background: "#0aef70", width: stats.perc.completed + "%" }}>
            </div>
        </div>
        return <div key={this.uname} className="cgs-col">
            <div className={`w3-padding-small ${cls} `}>
                <div>
                    <Link to={`/${this.type}/${this.uname}`}><b>{this.name}</b></Link>
                    <span className="cgs-small-tag  w3-right w3-blue">{this.filtered}</span>
                </div>
                {progressUI}
                {topSection}
            </div>
            {list}
        </div>
    }
}
