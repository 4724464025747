import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux'

export const MilestoneSwitch = () => {
    const milestones = useSelector(item => item.milestones);
    const [milestoneName, setMilestoneName] = useState('CurrentSprint');
    let [sp] = useSearchParams();

    useEffect(() => {
        const mname = sp.get('milestone'); 
        const ms = milestones.find(item => item.title === mname);
        if (ms) {
            setMilestoneName(ms.title)
        }
    }, [sp,milestones])
    const np = new URLSearchParams(document.location.search)
    return <div className="w3-dropdown-hover w3-padding " style={{ position: "relative" ,background:'transparent'}} >
        <button className="w3-button w3-indigo">{milestoneName}
            <span className="ion-chevron-down" style={{
                fontSize: "10px",
                marginLeft: "10px"
            }}></span>
        </button>
        <div className="w3-dropdown-content w3-bar-block w3-border"
            style={{ right: "0px", height: "400px", maxHeight: '400px', width: '240px', overflow: 'auto' }}>
            {milestones.map(item => {
               
                np.set('milestone',item.title)
                return <Link key={item.id}
                    to={`${document.location.pathname}?${np.toString()}`} className={`w3-bar-item cgs-list-milestone  w3-button ${item.state == 'active' ? '' : 'w3-blue-grey'} `}>{item.title}
                    <span className="w3-tiny  w3-right w3-purple w3-round" title={`${item.start_date} - ${item.due_date}`}>{moment(item.start_date).format("DD")}-{moment(item.due_date).format("DD")}</span>
                </Link>
            })}
        </div>
    </div>
}
