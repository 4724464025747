import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import moment, { parseTwoDigitYear } from 'moment';
import { iAxios } from '../../utils';
import { Spinner } from '../Helper';
import { Month, LinearMonth } from '../common/Calendar';

export const Leaves = (props) => {

    const [email, setEmail] = useState("");
    const [category, setCategory] = useState("all");
    const [loading, setLoading] = useState(false);
    const [itemList, setResponse] = useState([]); 

    const dbSetEmail = debounce(setEmail, 200);
    function fetchLeaves() {
        return new Promise((resolve, reject) => {
            iAxios.get(`/api/v1/leaves`).then(resp => {
                resolve(resp.data.data);
            }).catch(ex => {
                console.error(ex);
                reject(ex);
            });
        })
    }

    useEffect(() => {
        setLoading(true);
        setResponse([]);
        fetchLeaves().then(data => {
            setLoading(false); 
            const list = data.filter(item => item.assigneeUser).sort((a, b) => {
                return moment(a.dueDate).toDate().getTime() - moment(b.dueDate).toDate().getTime();
            });
            setResponse(list);
        }).catch(ex => {
            console.error(ex); 
            setLoading(false);
        });
    }, [1])

    const fetchData = () => {
        
    }
    const filteredItems = itemList.filter(item => {
        if (category != 'all' && category != item.category) {
            return false;
        }
        if (email != '' && item.assigneeUser) {
            return item.assigneeUser.email.indexOf(email.toLowerCase()) >= 0;
        }
        return true;
    });

    const months = filteredItems.reduce((agg, item) => { 
        let duration = item.timeEstimate / (60 * 60);
        let date = moment(item.dueDate);

        while (duration > 0) {
            const monYr = date.startOf('mon');
            const mon = monYr.format('YYYY-MM-01');
            const day = date.format('ddd');
            if (day === 'Sun' || day === 'Sat') {
                date.add(1, 'day');
                continue;
            }

            if (!agg[mon]) {
                agg[mon] = []
            }
            agg[mon].push({
                id: item.id,
                duration: item.timeEstimate,
                date: date.format('YYYY-MM-DD'),
                title: item.assigneeUser.email + " - " + item.title
            });
            duration -= 8;
            date.add(1, 'day');
        }
        return agg;
    }, {}) 

    const people = itemList.reduce((agg, item) => {
        if (item.category == 'combo_off') {
            return agg;
        }
        if (!agg[item.assigneeUser.name]) {
            agg[item.assigneeUser.name] = 0
        }
        agg[item.assigneeUser.name] += Math.floor(item.timeEstimate / (3600 * 8))
        return agg;
    }, {})

    filteredItems.reverse();

    
    return <div>

        <form className="w3-row-padding w3-margin" onSubmit={e => fetchData(e)} >
            <div className="w3-col m6">
                <input className="w3-input" onChange={e => dbSetEmail(e.target.value)} />
            </div>
            <div className="w3-col m3 ">
                <select className="w3-input" onChange={e => setCategory(e.target.value)}>
                    <option value="all">All</option>
                    <option value="leave">Leave</option>
                    <option value="combo_off">Combo Off</option>
                </select>
            </div>
            <div className="w3-col m3">
                <a href="https://gitlab.ceegees.in/ceegees/all-devs/management/-/issues?scope=all&utf8=%E2%9C%93&state=all&not[label_name][]=OnLeave&search=leave"
                    target="_blank">Incorrect Labels</a>
            </div>
        </form>
        <div className="w3-padding-small  " >
            <LinearMonth months={months} />
        </div> 
        <div style={{ display: 'none', flexWrap: 'wrap' }}>
            { Object.keys(months).map(mon =>
                <div key={mon} className="w3-padding-small m3 s6" style={{ display: 'flex' }}>
                    <Month mon={mon} data={months[mon]} />
                </div>
            )}
        </div>
        <div className="w3-row-padding w3-margin-top">
            {filteredItems.length > 0 && <div className="w3-col m8"><table className="w3-table-all ">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>User</th>
                        <th>Days</th>
                        <th>Approver</th>
                        <th>Info</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredItems.map((item, idx) => {

                        return <tr>
                            <td>{idx + 1}</td>
                            <td style={{textAlign:"right"}}>{moment(item.dueDate).format('YYYY-MM-DD')}</td>
                            <td>{item.assigneeUser.email}</td>
                             <td>{item.timeEstimate ? item.timeEstimate/(8*3600):'time not marked'}</td>
                            <td>{item.approver ? item.approver.email : 'Not Approved'}</td>
                            <td><a target="_blank" href={item.url}>{item.title}
                                {item.dueDate == null &&
                                    <span title="Date not marked" className="ion-alert-circled w3-margin-left w3-text-red ion"></span>}
                            </a></td>
                        </tr>
                    })}</tbody>
            </table>
            </div>}
            <div className="w3-col m4">
                <table className="w3-table w3-table-all">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>User</th>
                            <th>Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(people).sort().map((name, idx) => {
                            return <tr>
                                <td>{idx + 1}</td>
                                <td>{name}</td>
                                <td>{people[name]}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>

        {loading && <div className="w3-center"><Spinner /></div>}

    </div>
}