import React, { useState, useEffect, useCallback } from 'react';
import { iAxios } from '../../utils';
import { Spinner } from '../Helper';
import { Link, useLocation, useParams } from 'react-router-dom';
import { StatusWidget } from '../widgets/JiraStatus';
import { JiraIssueItem as IssueItem } from './JiraIssue';
import moment from 'moment';
export const useQuery = () => {
    const location = useLocation();
    return new URLSearchParams(location.search);
}

export const Jira = () => {
    const { projectId, componentId = 'EMPTY' } = useParams();
    const query = useQuery(); 

    const [jql, setJql] = useState('');
    const [search, setSearch] = useState('')
    const [issues, setIssues] = useState([]);
    const [sprintId, setSprintId] = useState('');
    const [runStatus, setRunStatus] = useState('');
    const [startId, setStartId] = useState('');
    const [createId, setCreateId] = useState('');
    const [versions, setVersions] = useState([]);
    const [token, setToken] = useState(localStorage.getItem('jira_token') || '');
    const [version, setVersion] = useState('');
    const [components, setComponents] = useState(null);
    const [sprints, setSprints] = useState([]);
    const [dialog, setDialog] = useState('');

    const [total, setTotal] = useState(0);
    const page = query.get('page') || '0';


    useEffect(() => {
        if (token && token !== '') {
            localStorage.setItem('jira_token', token);
        }
    }, [token])

    useEffect(() => {
        setComponents(null);
        iAxios.get(`/api/v1/jira`, {
            params: {
                path: `/rest/api/3/project/${projectId}/version`
            }
        }).then(resp => {
            setVersions(resp.data.values.filter(item => !item.released));
        }).catch(ex => {
            console.error(ex);
        });

        const boardId = '458';
        iAxios.get('/api/v1/jira', {
            params: {
                path: `/rest/agile/1.0/board/${boardId}/sprint`
            }
        }).then(resp => {
            setSprints(resp.data.values);
        });

        iAxios.get(`/api/v1/jira`, {
            params: {
                path: `/rest/api/3/project/${projectId}/components`
            }
        }).then(resp => {
            setComponents([{ name: 'EMPTY' }].concat(resp.data));
        }).catch(ex => {
            console.error(ex);
        });

    }, [projectId]);

    const renderRunStatus = async () => {
        setDialog('run-status')
    }


    useEffect(() => {
        const selected = sprints.find(item => item.id === sprintId);
        if (!selected) {
            return;
        }
        const ql = `project=SNT AND issuetype = TestCaseRun AND created > "${moment(selected.startDate).format('YYYY-MM-DD 00:00')}"  order by created ASC`;
        iAxios.get(`/api/v1/jira`, {
            params: {
                path: `/rest/api/3/search?maxResults=3&jql=${encodeURIComponent(ql)}`
            }
        }).then(resp => {
            if (resp.data.errorMessages) {
                alert(resp.data.errorMessages.join(','))
            } else {
                setStartId(resp.data.issues[0].id);
            }
        }).catch(ex => {
            console.error(ex);
        });

    }, [sprints, sprintId])


    useEffect(() => {
        if (!jql || jql === '') {
            return;
        }
        setIssues(null);
        iAxios.get(`/api/v1/jira`, {
            params: {
                path: `/rest/api/3/search?expand=renderedFields&startAt=${page * 50}&jql=${encodeURIComponent(jql)}`
            }
        }).then(resp => {
            if (resp.data.errorMessages) {
                alert(resp.data.errorMessages.join(','))
                setIssues([]);
                setTotal(0)
            } else {
                setIssues(resp.data.issues);
                setTotal(resp.data.total)
            }
        }).catch(ex => {
            console.error(ex);
        });
    }, [jql, page]);

    const qJql = query.get('jql');

    useEffect(() => {
        setTotal(0);
        if (componentId === 'jql') {
            setSearch(qJql);
            setJql(qJql);
            return;
        }
        let comp = componentId !== 'EMPTY' ? `"${componentId}"` : 'EMPTY';
        const ql = `project=${projectId} AND issuetype = Task AND component =${comp}`;
        setSearch(ql);
        setJql(ql);
    }, [componentId, projectId, qJql])


    const handleSearch = (e) => {
        e.preventDefault();
        const val = e.target.action; 
        const formData = new FormData(e.target);
        let search = new URLSearchParams(formData);
        let queryString = search.toString();
        // history.push(`/jira/${projectId}/jql?${queryString}`);
    }

    const handleBulkCreate = () => {
        if (runStatus !== '') {
            setRunStatus('');
            return;
        }
        if (!window.confirm('Are you sure you want bulk create test cases all issues on this page')) {
            return;
        }

        setRunStatus('creating');
        setCreateId(issues[0].key);
    }

    const setNextCreateId = (cid) => {
        if (runStatus === '') {
            setCreateId('');
            return;
        }
        if (!issues) {
            setRunStatus('');
            return;
        }

        const pos = issues.findIndex(item => item.key === cid);
        if (issues.length > pos + 1) {
            setCreateId(issues[pos + 1].key);
        } else {
            setCreateId('');
            setRunStatus('');
            alert('Bulk creation completed');
        }
    }

    const viewSprintRuns = () => {

        const params = {
            page: 0,
            jql: `project=SNT AND issuetype = TestCaseRun AND Sprint=${sprint.id} ${version !== '' ? `AND fixVersion="${versionName}"` :''}ORDER BY status ASC` 
        };
        const search = new URLSearchParams(params);
        const queryString = search.toString();
        // history.push(`/jira/${projectId}/jql?${queryString}`);
    }

    const sprint = sprints.find(item => item.id === sprintId);
    let versionName = '';
    const v = versions.find(item => item.id == version);
    if (v) {
        versionName = v.name;
    }

    return <div>
        {dialog === 'run-status' && <StatusWidget projectId={projectId} sprintId={sprintId} closePopup={e => setDialog('')} />}
        <div className="w3-row ">
            <div className="w3-col m4 l3" >
                <div className="w3-cener w3-light-grey w3-padding">
                    <h4>{projectId}</h4>
                </div>
                <div className="w3-padding" style={{ height: "90vh", overflow: 'scroll' }}>
                    {sprints && <select name="sprintId" onChange={e => setSprintId(parseInt(e.target.value))}
                        className="w3-input w3-border w3-padding-small w3-margin-bottom">
                        <option value="">Select Sprint</option>
                        {sprints.map(item => <option value={item.id}>{item.name} {item.state !== 'active' ? ` - ${item.state}` : ''}</option>)}
                    </select>}

                    {versions && <select name="versionId" onChange={e => setVersion(e.target.value)}
                        className="w3-input w3-border w3-padding-small w3-margin-bottom">
                        <option value="">Select Version</option>
                        {versions.map(item => <option value={item.id}>{item.name}</option>)}
                    </select>}
                    <div className="w3-margin-bottom w3-right-align">
                        <input name="token" value={token} type="password" onChange={e => setToken(e.target.value)}
                            className="w3-input w3-border w3-padding-small" placeholder="Enter your token" />
                        {token === '' && <a target="_blank" rel="noreferrer"
                            href="https://id.atlassian.com/manage-profile/security/api-tokens"
                            className="w3-small w3-text-grey">create your api token by clicking here.</a>}
                        <div>
                            {sprint && <button className="w3-button w3-padding-small w3-left w3-margin-top w3-purple w3-round w3-margin-bottom "
                                onClick={renderRunStatus}>View Sprint Status</button>}
                            {sprint && <button className="w3-button w3-padding-small w3-right w3-margin-top w3-margin-bottom "
                                onClick={viewSprintRuns}>View Sprint Runs</button>}

                        </div>
                    </div>
                    <table className="w3-table w3-table-all">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {components && components.map((item, idx) => {
                                return <tr className={item.name === componentId ? 'w3-green' : ''}>
                                    <td>{idx + 1}</td>
                                    <td><Link to={`/jira/${projectId}/${item.name}`}>{item.name}</Link>
                                        <Link to={`/jira/${projectId}/${item.name}`} className="w3-circle w3-right w3-button">
                                            <i className="ion-arrow-right-b"></i></Link>
                                        <div className="w3-small w3-text-grey">{item?.lead?.displayName}</div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>

                    {components == null && <div className="w3-center"><Spinner /></div>}
                </div>
            </div>
            <div className="w3-col m8 l9">
                <div className="w3-row-padding w3-light-grey w3-padding">
                    <div className="w3-col m6  ">
                        <h4>{componentId}</h4>
                    </div>
                    <div className="w3-col m6 w3-right-align">
                        {total > 0 && <span className="w3-padding w3-margin-right">
                            showing {page * 50 + 1}  to {((parseInt(page) + 1) * 50)} of {total}</span>}
                        {page > 0 && <Link className="w3-padding-small w3-button w3-border w3-round"
                            to={`/jira/${projectId}/${componentId}/?jql=${jql}&page=${parseInt(page) - 1}`}>Prev</Link>}
                        {page * 50 < total && <Link className="w3-padding-small w3-button w3-border w3-round"
                            to={`/jira/${projectId}/${componentId}/?jql=${jql}&page=${parseInt(page) + 1}`}>Next</Link>}
                    </div>
                </div>
                <div className="w3-padding">
                    <form onSubmit={handleSearch} action={`/jira/${projectId}/jql`} className="w3-row-padding w3-margin-bottom w3-stretch">
                        <div className="w3-col m10">
                            <input name="jql" className="w3-input w3-border" value={search} onChange={e => setSearch(e.target.value)} />
                        </div>
                        <div className="w3-col m2">
                            <button id="search_button" className="w3-button w3-blue w3-round" >Search</button>
                            {(sprint && sprint.state === 'future') && <button className="w3-button w3-orange w3-round w3-margin-left"
                                disabled={version === ''}
                                type="button" onClick={handleBulkCreate} >{runStatus === '' ? 'Bulk' : 'Cancel'}</button>}
                        </div>
                    </form>
                    {issues == null && <div className="w3-center"><Spinner /></div>}
                    {issues && issues.map((item, idx) => {
                        return <IssueItem key={item.key} components={components}
                            token={token}
                            version={version}
                            projectId={projectId}
                            startId={startId}
                            createId={createId}
                            setNextCreateId={setNextCreateId}
                            item={item} idx={page * 50 + idx}
                            sprint={sprint} />
                    })}
                </div>
            </div>
        </div>
    </div >
}