import React, {Component} from 'react'; 
import {Link} from 'react-router-dom';

export const Spinner = (props = {message:'Loading Data', mode:'big' }) => { 
    let  paddingCls = 'w3-padding-64';
    if (props.mode == 'small'){
        paddingCls='w3-padding-small cgs-loader-small';
    }
    return <div className={`w3-center ${paddingCls} w3-block`} > 
        <div className="w3-center w3-padding">
        <div className="cgs-loader"></div>
        </div>
       {props.message}
    </div>
}
 

export const TRWrapper = (props) => {
    return <tr className={props.className}><td colSpan={props.colSpan}>{props.children}</td></tr>
}

export const ButtonGroup = ({buttons,onSelect,selected}) => {
    return <div className="w3-bar w3-center"> 
        {buttons.map(b => {
            let cls = '';
            if (b == selected){
                cls = 'w3-blue';
            }
            return <button key={b} onClick={onSelect.bind(this,b)} className={`w3-button w3-small ${cls}`}>{b}</button>
        })}
    </div>
}
export const TabFilter =(props) => {
    
    const {name,count,filters} = props;

    const qs = new URLSearchParams(document.location.search);
    
    const current = qs.get(name);
    const tabs = filters.map(item => { 
        let cls = '';
        let strName = item;
        let label = null;
        if (current == item.toLowerCase()){
            cls = 'w3-light-grey';
            if(count > 0){
                label = <span className="w3-badge w3-red">{count}</span>
            }       
        }
        qs.set(name,item.toLowerCase())
        return <Link key={name} className={`w3-bar-item cgs-tab-bar w3-button ${cls} `}
            to={`${document.location.pathname}?${qs.toString()}`}>
            {strName} {label}
        </Link>
    })
    
    return <div className="w3-padding-small">
    {tabs}
    </div>
}
