
import React, { Component } from 'react';
import { Spinner } from '../Helper';
import { formatSecs, getIssueProject, paginatedFetch, iAxios } from '../../utils.js'  
import moment from 'moment';


const RelatedBlock = ({ item }) => {
    return <div className="w3-blue-grey w3-round" style={{
        margin: "10px 4px 0px 0px",
        padding: "10px 6px"
    }}>
        <a className="w3-block w3-small"
            href={item.url}>{item.title} <br />
            <span>S: {formatSecs(item.timeSpent)}/E : {formatSecs(item.timeEstimate)}</span>
            <span className="w3-tag cgs w3-red -grey w3-round w3-small">{item.milestone ? item.milestone.title : 'NoMilestone'}</span>
        </a>
    </div>
}

export default class IssueBlock extends Component {
    constructor(arg) {
        super(arg);
        this.state = {
            linked: null,
            deleted: false
        };
    }
    componentDidMount() {
        const { item, loadLinked, labelsToRemove } = this.props;
        if (!loadLinked) {
            return;
        }
        if (item.labels.indexOf("TaskSplit") == -1) {
            return;
        }
        if (this.state.linked != null) {
            return;
        }
        const project = getIssueProject(item);
        this.setState({ linked: <Spinner mode="small" /> })
        paginatedFetch('/api/v1/related-issues', {
            project: project,
            iid: item.gitlabId
        }).then(resp => {
            const list = resp.data.map(item => {
                return <RelatedBlock key={item.id} item={item} />
            });
            this.setState({ linked: list });
        });
    }

    deleteItem(id) {
        iAxios.post('/api/v1/delete-issue', {
            id
        }).then(result => {
            this.setState({ deleted: true });
        });
    }
    render() {
        const { item, labelsToRemove, user } = this.props;
        const labels = item.labels.filter(l => labelsToRemove.indexOf(l) < 0);
        const project = getIssueProject(item);
        let bg = "#FFFFFF";
        let color = 'black';
        let overShoot = null;
        if (this.state.deleted) {
            return null;
        }
        if (item.timeEstimate === null) {
            bg = "#DEDEDE";
        } else if (item.timeEstimate < item.timeSpent) {

            let perc = (item.timeSpent / item.timeEstimate) - 1;
            if (perc > 0) {
                bg = `rgba(255,0,0,${perc / 2})`;
                color = "black";
                if (perc / 3 > 0.7) {
                    color = 'white';
                }
            }
            overShoot = <span data-col={`${bg}`} > OverShot : {Math.round(perc * 100)}%</span>
        }

        const st = {
            color: color,
            background: bg,
        };

        if (item.state == 'closed') {
            st.borderTop = 'solid 4px #0ed60e';
        }

        if (item.timeEstimate > 16 * 60 * 60) {
            st.borderBottom = 'solid 4px #ce3100';
        }
        return <div className="cgs-issue-block w3-display-container" style={st} id={item.id}>
            {item.complexity ? <span className="w3-right w3-badge w3-blue-gray w3-small">{item.complexity}</span> : null}
            <a key={"issue_" + item.id}
                className="w3-block "
                target="_blank"
                href={item.url}>
                {item.title}
            </a>
            <br />
            <span>Spent : {formatSecs(item.timeSpent)} /  Estimate : {formatSecs(item.timeEstimate)}</span> <br/>
            <span>Due : {moment(item.dueDate).format('MMM DD')}</span> <br/>
            
            {overShoot}<br />
            <span className="w3-tag cgs w3-green w3-small "> {project} </span>
            {
                labels.map(p => <span key={p}
                    className="w3-tag cgs w3-blue w3-small w3-round">{p}</span>)
            }
            {this.state.linked}
            {['giju', 'soja'].some(item => user?.email.indexOf(item) >= 0) && <button
                onClick={() => this.deleteItem(item.id)}
                className="w3-button w3-padding-small w3-round w3-display-hover w3-display-bottomright" style={{ margin: '5px' }}>delete</button>}
        </div>

    }
}
