import React, { Component, Fragment, useEffect, useState } from "react";
import { ButtonGroup } from "./../Helper"; 
import { fetchData, formatDay, iAxios } from "../../utils";
import moment from 'moment';

const Actions = {
  CheckedIn:'Checked In',
  CheckedOut:'Checked Out',
  Lunch:'Lunch',
  Active:'Active',
  // Leave:'Out',
  Busy:'Busy'
};

const ActionMap = {
  'Active' : 'In',
  'Checked In' : 'In',
  'Checked Out': 'Out',
  'Check In' : 'In',
  'Check Out': 'Out',
  'Lunch Break':'Break',
  'Casual Break':'Break', 
  'Lunch': 'Out',
  'Busy':'In',
}
  
const UsersStatus = ({ user }) => {

  const [statusName, setStatusName] = useState('CheckIn');
  const [buttonColor, setButtonColor] = useState('w3-grey');
  const [action, setAction] = useState(null);

  const fetchData =  ()=>{
    iAxios.get('/api/v1/user-log').then(resp => {
      if (!resp?.data?.data) {
        return;
      }
      const c = resp.data.data.action;
      setAction(resp.data.data);
      setStatus(c);
    });
  }

  const noCheckinToday = false;
  useEffect(() => {
    fetchData();
    const timer = setInterval(fetchData,600*1000);
    
    window.addEventListener("storage", (ev)=>{
      console.log('stroage',ev);
    });
    return ()=>{
      clearInterval(timer)
    }
  }, [1])

const setStatus = (action)=>{  
    if (action === Actions.CheckedIn || action === Actions.Active) {
      setStatusName(Actions.Active);
      setButtonColor('w3-green');
    } else if (action === Actions.Busy) {
      setStatusName('Busy');
      setButtonColor('w3-orange')
    } else {
      setStatusName('Check In');
      setButtonColor('w3-grey');
    }  
}
 
  const btnCls = 'w3-button w3-padding-small w3-round ';

  let secs = 0;
  if (action) {
    secs = Math.floor((Date.now() - action.action_time) / 1000);
  }


  return (
    <Fragment>
      {user ? 
      <span style={{marginTop:'4px'}}> 
        {action && <span className="w3-margin-left">{action.action} 
          <span className="w3-text-aqua w3-padding-small">({moment.unix(action.action_time/1000).fromNow()}
        )</span></span>}
      </span>
      :<span className="w3-padding-64 w3-center">...</span>}

    </Fragment>
  );
}

export default (UsersStatus);
