import React, { Component, useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { Spinner, TabFilter } from '../Helper'; 
import {  getMilestone, fetchData, iAxios } from '../../utils';

import {  useIssueFetcher } from '../common/components'; 

export const TeamActivity = (props) => {

    const [data, setData] = useState([]);
    const [timer, setTimer] = useState(Date.now());

    useEffect(() => {
        iAxios.get('/api/v1/team-activity').then(resp => {
            console.log(resp);
            const list = resp.data.data;
            setData(list);
        })

    }, [timer])
    useEffect(() => {
        const timerId = setInterval(() => {
            setTimer(Date.now());
        }, 5e4)
        return () => {
            clearInterval(timerId);
        }
    }, [1]);
    return <div className='w3-padding w3-container w3-center'>
        {data.map(item => {
            return <div key={item.name} className='w3-show-inline-block w3-black  w3-round' style={{ margin: "4px" }}>
                <div style={{ position: 'relative' }}>
                    {(!item.screens || item?.screens.length === 0) && <div
                        className='no-screen-update w3-red' >Not updated</div>}
                    {item?.screens?.map(screen => {
                        if (screen.tm < Date.now() - 36e5) {
                            return <div className='no-screen-update w3-left w3-orange' >
                                Last Updated : {moment(screen.tm).fromNow()}
                            </div>
                        }
                        return <div className='w3-left'>
                            <img className="user-screen" src={screen.url} alt="screenshot " /><br />
                            {moment(screen.tm).fromNow()}
                        </div>
                    })}
                    {item.statusGroup !== 'In' && <div className='w3-yellow '
                        style={{ position: 'absolute', right: '2px', fontSize: '10px', top: '2px', padding: '2px 4px' }}>
                        {item.status}
                    </div>}


                </div>
                <div className='w3-center'>
                    {<a href={`/member/${item.username}/CurrentSprint/activity`}
                        style={{ textDecoration: 'none' }}
                        target='_blank' rel='noreferrer' >{item.name}</a>}
                </div>
            </div>
        })}
    </div>
}
const FinRow = (props) => {
    const { isAfter, content, idx } = props;
    const [isFull, showFull] = useState(false);
    const inital = content.split('\n')
    const res = inital.reduce((agg, item) => {
        if (item.indexOf('-') == 0 && isFull == false && inital.length > 1) {
            return agg;
        }
        agg.push(item);
        return agg;
    }, []);

    return <td>
        {inital.length > 1 ? <div style={{ whiteSpace: 'pre-wrap' }}>
            {res.join('\n')}
        </div> : <div>{res.join('')}</div>}
        {(inital.length != res.length || isFull) && <button onClick={e => showFull(!isFull)}
            className='w3-button w3-margin-top w3-center w3-padding-small w3-border w3-round w3-tiny ' >
            {isFull ? 'Show Less' : 'Show More'}
        </button>}
        {(idx == 0 && isAfter) && <div>Projection</div>}
    </td>
}
export const Financials = (props) => {

    const [finData, setFindAta] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [total, setTotal] = useState(null);
    useEffect(() => {
        fetchData('/api/v1/fins', {}).then(resp => {
            setFindAta(resp.data);
            const sum = resp.data.reduce((total, item) => {
                // console.log(item['Balance'],total)
                // return total + parseFloat(item['Balance'].replace(',',''), 10)

                return 0;
            }, 0)
            // setTotal(sum)
            setHeaders(Object.keys(resp.data[0]))
        })
    }, [])
    return <div className='w3-padding'>
        <div>
            <h3 className='w3-left'>Utilisation</h3>
            {total !== null && <h6 className='w3-right'>Bonus Projection : {Math.floor(total)}</h6>}
        </div>
        <table className='w3-table   w3-border  w3-striped'>
            <thead >
                <tr className=' w3-leftbar w3-border'>
                    {headers.map(item => <th className='w3-bold'><b>{item}</b></th>)}
                </tr>
            </thead>
            <tbody>
                {finData.map((row) => {
                    const isAfter = moment(row['Month']).endOf('M').isAfter(Date.now())
                    return <tr className={isAfter ? 'w3-border-yellow w3-leftbar' : 'w3-leftbar w3-border-blue'}>
                        {headers.map((item, idx) => <FinRow idx={idx} content={row[item]} isAfter={isAfter} />)}
                    </tr>
                }
                )}
            </tbody>
        </table>

    </div>
}


const Status = (props) => {

    const {issueState,userFilter,filter,milestone,reloadBtn ,fetchData} = useIssueFetcher()

    let filters = ['All', 'Open', 'Plan', 'Backlog', 'Dev', 'QA', 'Done'],
        content = null,
        total = 0;
    const { userList } = issueState; 

    if (userList == null) {
        content = <Spinner />;
    } else if (userList.length == 0) {
        content = <div className="w3-center w3-padding-64 w3-block" >
            Empty Result
        </div>
    } else {
        content = userList.reduce((acc, item) => {
            if (userFilter.length > 0) {
                if (!userFilter.some(userId => item.uname == userId)) {
                    return acc;
                }
            }
            const view = item.renderView(filter);
            if (item.filtered > 0) {
                acc.push(view);
            }
            return acc;
        }, []);
        if (content.length == 0) {
            content = <div className="w3-center w3-light-grey w3-padding-64 w3-block" >
                Current selection has no items to show.
            </div>
        } else {
            content =
                <div className="cgs-table cgs">
                    {content}
                </div>
        }
        total = userList.reduce((acc, item) => acc + item.filtered, 0);
    }

    let title = "";
    // if (milestoneObj) {
    //     title = moment(milestoneObj.start).format('Do MMM') + ' to '
    //         + moment(milestoneObj.end).format('Do MMM');
    //     title = title += ", " + formatSecs(workingDays(milestoneObj.start, milestoneObj.end) * 8 * 3600) + ",";
    // }

    return (<div>

        <div className="w3-bar cgs w3-theme-d2">
            <div className="w3-left">
                <TabFilter filters={filters}
                    name='filter' 
                    count={total} />
            </div>
            <div className="w3-right">
                <a className="w3-button w3-bar-item w3-green"
                    href={`https://gitlab.ceegees.in/groups/ceegees/-/boards?scope=all&utf8=%E2%9C%93&state=opened&milestone_title=${milestone}`}
                    target="_blank">Update</a>
                <button
                    ref={reloadBtn}
                    onClick={e => fetchData('refresh')}
                    className="w3-bar-item w3-blue w3-bar-item w3-button">
                    <i className="ion-refresh"></i>
                </button>

            </div>
        </div>
        <div>
            <div className="w3-responsive" style={{ minHeight: "100vh" }}>
                {content}
            </div>
        </div>
    </div>)

}

export default Status;