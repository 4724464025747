
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { iAxios, getDiffClass } from '../../../utils';
import moment from 'moment';

import ReactMarkdown from 'react-markdown'
import { useDispatch } from 'react-redux';

const Names = ({ list, max = 2 }) => {
    const items = [];
    while (items.length < max && list.length > 0) {
        items.push(list.shift());
    }
    if (list.length > 0) {
        items.push(`+${list.length} more`);
    }
    return <div className="w3-right cgs-capitalize" style={{ marginRight: '8px' }}> {items.join(', ')} </div>
}

const Reportees = ({ data, leadName, msgList }) => {
    const dispatch = useDispatch();


    const STATUS_COLOR_MAP = {
        'Active': 'w3-green',
        'Leave': 'w3-amber',
    }
    const triggerFilter = (leadId, leadName) => {
        const users = data.filter(row => row.teamLead === leadId).map(item => ({
            name: item.name,
            uname: item.username
        }));
        users.push({ uname: leadId, name: leadName })
        dispatch({
            type: 'filter_users',
            users
        });
    }
    return <ol className={leadName === '' ? "team-start" : "team-cont"}>
        {data.filter(item => item.teamLead === leadName)
            .map(item => {
                let msg = msgList.find(msg => msg.username === item.username);
                // if (!msg) {
                //     return null;
                // }

                let color = '';
                let infoCls = 'w3-text-orange';
                if (item.memberStats) {
                    color = getDiffClass(100 - item.memberStats.perc.estimate, [80, 50, 20]);
                }
                if (!msg && !item.memberStats) {
                    infoCls = 'w3-text-red w3-large';
                    msg = { msg: "*Need to add tickets for the sprint*" }
                }
                const statusColor = STATUS_COLOR_MAP[item.data.status] ? STATUS_COLOR_MAP[item.data.status] : 'w3-blue-gray';
                const hasRep = data.some(row => row.teamLead === item.username);
                return (<li key={item.username}>
                    <span className={item.memberStats ? '' : ' w3-text-red bold-text'}>
                        <Link className="cgs-plain-link " to={`/member/${item.username}/CurrentSprint`}>{item.name}
                            {item.data.status && <span className={`w3-small w3-label progress-label ${statusColor}`}
                                title={moment.unix(item?.data?.statusTime / 1000).fromNow()}> {item.data.status}</span>}
                        </Link>
                        {item.memberStats && <span><span className={`w3-label ${color} progress-label`}>
                            Est : {Math.round(item.memberStats.perc.estimate)}%
                        </span>
                        </span>
                        }

                        {msg?.msg && <span className="w3-dropdown-hover">
                            <i className={`ion w3-large ion-alert-circled ${infoCls} w3-margin-left`} />
                            <span className="w3-dropdown-content w3-padding-small w3-black">
                                <ReactMarkdown children={msg.msg} />
                            </span>
                        </span>}
                        {hasRep && <button onClick={e => triggerFilter(item.username, item.name)} className='w3-button w3-padding-small w3-margin-left'>
                            <i className={`ion ion-android-funnel `} />
                        </button>}
                    </span>
                    <Reportees data={data} leadName={item.username} msgList={msgList} />
                </li>)
            })}
    </ol>
}

const Leads = (props) => {
    const [data, setData] = useState([]);
    const [msgList, setMsgList] = useState([]);
    const loadData = () => {
        iAxios.get('/api/v1/users').then(res => {
            const nData = res.data.map(item => {
                const memberStats = props.userList.find(u => u.uname === item.username);
                return Object.assign(item, { memberStats: memberStats ? memberStats.getStats() : null });
            });
            setData(nData);
        });
        iAxios.get(`/api/v1/team-status/${props.milestone}`).then(res => {
            setMsgList(res.data.data);
        })

    }


    useEffect(() => {
        loadData();
    }, [1]);
    return <div className="w3-border w3-white w3-margin-bottom" style={{ minWidth: "320px" }}>
        <h4 className="w3-center w3-border-bottom " style={{ padding: "0px" }} >Team
            <button className="w3-button w3-small w3-padding-small  w3-right"
                onClick={e => loadData()}>Refresh</button>
        </h4>
        <Reportees data={data} leadName='' msgList={msgList} />
    </div>
}


export const BlogStatus = ({ userList }) => {
    const [blogData, setBlogData] = useState([])
    let total = 0;
    useEffect(() => {
        iAxios.get('/api/v1/users').then(res => {
            console.log(res);
        });
        iAxios.get('/api/v1/blog-status').then(res => {
            setBlogData(res.data)
        })
    }, [1])

    const rows = blogData.map((item, idx) => {
        total += item.posts?.nodes?.length
        return <tr key={item.id}>
            <td>{idx + 1}</td>
            <td>
                <a href={`https://blog.ceegees.in${item.uri}`} target='_blank' rel="noreferrer" > {item.name}</a>
            </td>
            <td>{item.posts?.nodes?.length}</td>
        </tr>
    })

    return <div className="w3-border w3-white w3-margin-bottom" style={{ minWidth: "320px" }}>
        <h4 className="w3-center w3-border-bottom " style={{ padding: "0px" }} >Blog Posts : {total}
        </h4>
        <table className='w3-table w3-table-all'>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Posts </th>
            </tr>
            {rows}
        </table>
    </div>
}

export default Leads;