import React, { Component, useEffect, useState } from 'react'
import moment from 'moment';
import { Spinner, TabFilter } from '../Helper';
import { useParams, useSearchParams } from 'react-router-dom'
import { formatSecs, paginatedFetch, getIssueProject, iAxios } from '../../utils'
import { MilestoneSwitch } from '../perf/MilestoneSwitch';
import IssueGroup from '../perf/IssueGroup';
import IssueBoard from '../widgets/IssueBoard';
import Header from '../widgets/Header';
import { ProjectContrib } from '../widgets/ProjectContrib';
const MOD_TM = 3e5 / 2;

export const UserActivity = (props) => {
    const [data, setData] = useState();
    const [engagement, setEngagement] = useState([]);
    const [date, setDate] = useState(moment().format());
    const [maxPage, setMaxPage] = useState(30);

    useEffect(() => {
        iAxios.get('/api/v1/issues-in-progress').then(resp => {
            console.log('in progress', resp.data);
        })
        iAxios.get('/api/v1/user-activity', {
            params: {
                date: moment(date).format('YYYY-MM-DD'),
                uname: props.uname
            }
        }).then(resp => {
            const list = resp.data.data;
            if (list.length === 0) {
                return;
            }
            list.sort((a, b) => b.tm - a.tm);
            setData(list);

            let last = list.length - 1;
            const startMin = Math.floor(list[last].tm / MOD_TM);
            const eng = [];
            const max1 = startMin + Math.floor((36e5 * 9.5) / MOD_TM);
            const max = Math.floor(Date.now() / MOD_TM);
            for (let min = startMin; min <= max1;) {
                const m1 = Math.floor(list[last].tm / (MOD_TM));
                let dec = false;
                const tmStr = moment(min * MOD_TM).format('hh:mm a');
                if (m1 < min) {
                    dec = true;
                } if (m1 > min) {
                    dec = false;
                    eng.push({
                        min,
                        val: 0,
                        tmStr,
                        cls: 'w3-black'
                    });
                } else if (m1 === min) {
                    eng.push({
                        min,
                        val: 1,
                        tmStr,
                        url: list[last].url,
                        cls: 'w3-green'
                    });
                    dec = true;
                    min++;
                }

                if (dec && last > 0) {
                    last--;
                } else {
                    min++;
                    eng.push({
                        min,
                        tmStr,
                        cls: min < max ? 'w3-black' : 'w3-yellow',
                        val: 0
                    });
                }
            }
            setEngagement(eng);
        })
    }, [1, date]);

    const increaseMax = () => {
        setMaxPage(maxPage + 30);
    }

    return <div className='w3-right-align'>
        <input type="date" className='w3-margin w3-align-right' defaultValue={moment().format('YYYY-MM-DD')} onChange={e => setDate(e.target.value)} />
        <div className='w3-padding w3-container w3-center'>
            {data && <div className='activity-scroller'><table>
                <tr>
                    {engagement.map(item => <td
                        title={`${item.tmStr}`}
                        className={`activity-cell ${item.cls}`}>
                        {item.url && <img src={item.url} className='hover-img' alt={item.tmStr} />}
                        <div className='hover-text w3-black'>{item.tmStr}</div>
                    </td>)}
                </tr>
            </table></div>}
            {data && data?.filter((item, idx) => idx < maxPage).map(item => (<div className='w3-show-inline-block  w3-border  w3-round'
                style={{ margin: '4px' }}>
                <a href={item.full} rel="noreferrer" target='_blank' alt="view in new tab">
                    <img src={item.url} alt="screenshot " style={{ height: '128px' }} />
                </a>
                <div className='w3-center'>
                    {moment(item.tm).format('hh:mm a')}
                </div>
            </div>))}
            {maxPage < data?.length && <div className='w3-center'><button className='w3-button w3-round w3-border w3-border-blue' onClick={increaseMax}>
                Showing {maxPage}/ {data.length}  Show More</button></div>}
        </div>
    </div>
}

const Member = () => {

    const [sp] = useSearchParams();
    const { username } = useParams();
    let uname = 'giju'
    if (username) {
        uname = username;
    }
    const user = {}
    let filter = sp.get('filter');
    if (!filter) {
        filter = 'all'
    }

    let milestone = sp.get('milestone');

    const [issueList, setIssueList] = useState([]);
    const [projectList, setProjectList] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const processIssues = (list, meta) => {
        const map = list.reduce((acc, item) => {
            const name = getIssueProject(item);
            if (!acc[name]) {
                acc[name] = new IssueGroup(name, name, 'project');
            }
            acc[name].addIssue(item);
            return acc;
        }, {});
        setIssueList(list);
        setProjectList(Object.values(map).sort((a, b) => a.totalEstimate - b.totalEstimate));

        setBtnDisabled(false);
    }

    const fetchData = (mode = '') => {
        setProjectList(null);
        setBtnDisabled(true);
        // if (!uname) {
        //     uname = user?.username;
        // }

        if (!milestone) {
            milestone = 'CurrentSprint';
        }
        paginatedFetch('/api/v1/issues', {
            page: 1,
            mode,
            uname,
            milestone
        }).then(resp => {
            processIssues(resp.data, resp.meta);
        }).catch(err => {
            console.error(err);
            alert(err.meta.message);
            document.location.href = "/dashboard"
        });
    }

    useEffect(() => {
        fetchData();
    }, [milestone])
    let count = 0;
    let content = null;
    let totalEstimate = 0;
    let totalSpent = 0;

    if (!uname) {
        uname = user?.username;
    }
    if (projectList == null) {
        content = <div className="w3-center"><Spinner /></div>;
    } else if (filter == 'board') {
        content = <IssueBoard
            issues={issueList}
            mode='user'
            id={uname} />
    } else {
        content = projectList.reduce((acc, item) => {
            const view = item.renderView(filter);
            if (item.filtered > 0) {
                acc.push(view);
                count = item.filtered
            }
            return acc;
        }, []);
        count = projectList.reduce((acc, item) => acc + item.filtered, 0);
        if (content.length == 0) {
            content = <div className="w3-center w3-light-grey w3-padding-64"><h6>Empty List</h6></div>
        } else {
            content = <div className="w3-responsive">
                <div className="cgs-table" style={{ minHeight: "100vh" }}>
                    {content}
                </div>
            </div>
        }
    }

    if (issueList) {
        totalEstimate = issueList.reduce((acc, i) => acc + i.timeEstimate, 0);
        totalSpent = issueList.reduce((acc, i) => acc + i.timeSpent, 0);
    }

    const spanItem = <span className="w3-white w3-round w3-maring-left w3-display-inline-block"
        style={{
            marginLeft: "10px",
            padding: "10px 20px"
        }}
    >{`${formatSecs(totalSpent)} / ${formatSecs(totalEstimate)}`}</span>

    const link = `https://gitlab.ceegees.in/groups/ceegees/-/boards?scope=all&utf8=%E2%9C%93&state=opened&milestone_title=${milestone}&assignee_username=${uname}`;

    return (
        <div >
            <div className='w3-row'>
                <div className='w3-left'><a href={link} ><h2>{uname} </h2> </a>
                </div>
                <div className='w3-left w3-padding-16 w3-margin-left'>{spanItem}
                </div>
            </div>
            <div className="w3-bar cgs w3-theme-d2">
                <div className="w3-left">
                    <TabFilter
                        filters={['All', 'Plan', 'Open', 'Spill', 'Done']}
                        name='filter'
                        count={count}
                    />
                </div>
                <div className="w3-right">
                    <button disabled={btnDisabled}
                        onClick={e => fetchData('refresh')}
                        className="w3-bar-item w3-blue w3-button">
                        <i className="ion-refresh"></i>
                    </button>
                </div>
            </div>
            <div className="w3-row">
                {content}
            </div>
        </div >
    )

}

export default Member;
