import { iAxios } from '../utils';

export const REQUEST_APPS = 'REQUEST_APPS'
export const RECEIVE_APPS = 'RECEIVE_APPS'

export const actionClearStorage = ()=> {
  const keys = Object.keys(localStorage);
  for(const key of keys){
    localStorage.removeItem(key);
  }
} 

export const fetchMilestones = () => (dispatch) => {
  return iAxios('/api/v1/gitlab-milestones')
    .then(resp => {
      dispatch({
        type: 'SET_MILESTONE_LIST',
        milestoneList: resp.data.data
      });
      return resp;
    });
};

export const fetchUserInfo = () => (dispatch) => {
  return iAxios.get('/api/auth/user').then(resp => {
    dispatch({
      type: 'SET_USER_INFO',
      data: resp.data.data
    });
    return resp;
  });
}


export const setMilestoneName = (name) => {
  return {
    type: 'SET_MILESTONE_NAME',
    milestoneName: name
  };
}

