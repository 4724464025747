import React from 'react';
import moment from 'moment';

export const Month = (props) => {
    const { mon, data } = props;
    const dt = moment(mon).startOf('month');

    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    let currIdx = 0;
    const weeks = [];
    const currMon = dt.format('M');
    const monYr = `${dt.format('MMMM')} ${dt.format('YYYY')}`;
    let total = 0;
    let totalCount = 0;
    while (currIdx < 42) {
        const pos = currIdx % 7;
        const day = dt.format('ddd');
        const cWIdx = Math.floor(currIdx / 7);
        if (weeks[cWIdx] === undefined) {
            weeks[cWIdx] = [];
        }

        if (dt.format('M') !== currMon) {
            weeks[cWIdx].push(<td key={currIdx}></td>);
            currIdx++;
            continue;
        }

        if (days[pos] !== day) {
            weeks[cWIdx].push(<td key={currIdx}></td>);
            currIdx++;
            continue;
        }
        const dtFmt = dt.format('YYYY-MM-DD');
        const list = data.filter(item => moment(item.date).format('YYYY-MM-DD') === dtFmt);
        const counts = list.length
        weeks[cWIdx].push(<td key={`item_${currIdx}`} className="w3-dropdown-hover">
            <div>{dt.format('DD')}</div>
            {counts > 0 && <div className="w3-badge w3-green " >{counts}
                <div className="w3-dropdown-content w3-left-align w3-padding w3-border">
                    <ol className="w3-padding-small">
                        {list.map(c => <li key={c.id}>{c.title}</li>)}
                    </ol>
                </div>
            </div>}
            {counts === 0 && <span className="w3-badge w3-light-grey ">-</span>}
        </td>);
        dt.add(1, 'day');
        if (counts > 0) {
            total++;
            totalCount += counts;
        }
        currIdx++;
    }
    return <table className="w3-table w3-bordered w3-border w3-white">
        <tbody>
            <tr><td colSpan="7"><b>{monYr}</b>
                <span className="w3-right">{total} Days - {totalCount} </span>  </td></tr>
            <tr>{days.map(day => <td key={day}>{day}</td>)}</tr>
            {weeks.map(week => <tr>{week}</tr>)}
        </tbody>
    </table>

}


export const LinearMonth = (props) => {
    const { months } = props;  
    const headers = [<td key="month">Month</td>];
    for (var idx = 1; idx <= 31; idx++) {
        headers.push(<th key={idx}>{idx}</th>)
    }

    const keys = Object.keys(months);
    keys.sort((a,b) => b.localeCompare(a));

    return <table className="w3-table w3-table-all">
        <thead>
            <tr>
                {headers}
            </tr>
        </thead>
        <tbody>
            {keys.map(mon => {
                const data = months[mon];
                const dt = moment(mon).startOf('month');
                let currIdx = 0;
                const currMon = dt.format('M'); 
                const monYr = `${dt.format('MMMM')} ${dt.format('YYYY')}`;
                const dates = [];
                dates.push(<td style={{textAlign:'right'}} key={monYr}>{monYr}</td>);
                while (currIdx < 31) { 
                    const day = dt.format('ddd');
                    let cls = '';
                    if (day === 'Sun' || day === 'Sat') {
                        cls = 'w3-pale-yellow';
                    }
                    if (dt.format('M') !== currMon){
                        dates.push(<td key={currIdx}></td>);
                        currIdx++;
                        continue;
                    }
                    const dtFmt = dt.format('YYYY-MM-DD');
                    const list = data.filter(item => moment(item.date).format('YYYY-MM-DD') === dtFmt);
                    const counts = list.length;

                    let popupStyle = {};
                    if (currIdx > 15){
                        popupStyle = {
                            right:'0px'
                        };
                    }
                    dates.push(<td key={`item_${currIdx}`} className={`w3-dropdown-hover ${cls}`}> 
                        {counts > 0 && <div className="w3-badge w3-green " >{counts}
                            <div className="w3-dropdown-content w3-left-align w3-padding w3-border" style={popupStyle}>
                                {day}
                                <ol className="w3-padding-small" >
                                    {list.map(c => <li key={c.id}>{c.title}</li>)}
                                </ol>
                            </div>
                        </div>}
                        {counts === 0 && <span className="w3-badge w3-light-grey ">{day[0]}</span>}
                    </td>);
                    dt.add(1, 'day');
                    currIdx++;
                }
                return <tr key={mon} >
                    {dates}
                </tr>
            })}

        </tbody>
    </table>
}