import React, { useState, useEffect } from 'react';
import { iAxios } from '../../utils';

const replaceEx = new RegExp('/rest.*', 'ig')

const getIssueLink = (i) => {
    return i.self.replace(replaceEx, `/browse/${i.key}`);
}


const createTestRun = (elements, issue, projectId, version, sprint, token) => {
    const ticketId = elements?.ticketId?.value;
    let data = null;
    let method = 'POST';
    let path = '/rest/api/2/issue';
    if (ticketId) {
        const transition = elements?.transition.value;
        const comment = elements?.comment.value;
        path = `/rest/api/2/issue/${ticketId}/transitions`;
        method = 'put';
        data = {
            fields: {
                customfield_10008: parseInt(sprint),
                fixVersions: [{ id: version }]
            },
            update: {
                comment: [
                    {
                        add: {
                            body: comment
                        }
                    }
                ]
            },
            transition: {
                id: parseInt(transition)
            }
        }
    } else {
        data = {
            fields: {
                project: {
                    key: projectId
                },
                summary: `[TestRun] - ${issue.fields.summary.trim()}`,
                description: issue.fields.description,
                issuetype: {
                    name: 'TestCaseRun'
                },
                fixVersions: [{
                    id: version
                }],
                labels: issue.fields.labels,
                timetracking: {
                    originalEstimate: '2m',
                    remainingEstimate: '2m'
                },
                components: issue.fields.components,
                assignee: issue.fields.assignee,
                customfield_10008: parseInt(sprint),
                customfield_10035: issue.fields.customfield_10035
            },
            update: {
                issuelinks: [
                    {
                        add: {
                            type: {
                                name: "Relates",
                                inward: "relates to",
                                outward: "relates to"
                            },
                            outwardIssue: {
                                key: issue.key
                            }
                        }
                    }
                ]
            }
        };
    }
    return iAxios.post('/api/v1/jira', {
        method,
        path,
        data,
        token
    }).then(resp => {
        if (resp.data.errorMessages) {
            alert(resp.data.errorMessages.join(','))
            return;
        }
    }).catch(ex => {
        alert(ex.message);
    });
}

export const JiraIssueItem = ({ item, idx, projectId, version, setNextCreateId, token, createId, sprint, components, startId }) => {
    const [issue, setIssue] = useState(item);
    const [status, setStatus] = useState(false);
    const [transitions, setTransitions] = useState([]);
    const [mode, setMode] = useState('');

    const runs = issue.fields.issuelinks.filter(item => item.outwardIssue)
        .map(item => item.outwardIssue)
        .filter(item => item.fields.issuetype?.name === 'TestCaseRun');

    if (runs.length === 0 && issue.fields.issuetype.name === 'TestCaseRun') {
        runs.push(issue);
    }
    useEffect(() => {
        if (!status || !run) {
            return;
        }
        iAxios.get('/api/v1/jira', {
            params: {
                path: `/rest/api/3/issue/${issue.key}/transitions`
            }
        }).then(resp => {
            setTransitions(resp.data.transitions);
        })
    }, [issue, status]);
    useEffect(() => {
        if (status == false) {
            return;
        }
        if (issue.fields.issuetype.name !== 'TestCaseRun') {
            return;
        }
        const inward = issue.fields.issuelinks.find(item => item.inwardIssue); 
        if (inward){
            fetchIssue(inward.inwardIssue.key);
        }
        // fetchIssue(issue.fields.issuelinks[0].inwardIssue.key)
    }, [status])

    const createRun = (e) => {
        e.preventDefault();
        const elements = e.target.elements;
        setMode('in-flight');
        createTestRun(elements, issue, projectId, version, sprint.id, token)
            .then(() => {
                fetchIssue();
            })
    }


    useEffect(() => {

        if (issue.key !== createId) {
            return;
        }

        if (mode === 'in-flight') {
            return;
        }

        const pendingRuns = runs.filter(r => r.fields.status.statusCategory.key !== 'done');
        if (pendingRuns.length > 0) { 
            setNextCreateId(createId);
            return;
        }
 
        // setNextCreateId();
        // setTimeout(() => { setNextCreateId(createId) }, 5000);
        // return;

        setMode('in-flight');
        createTestRun({}, issue, projectId, version, sprint.id, token)
            .then(() => {
                fetchIssue();
                setNextCreateId(createId);
            })
    }, [createId]);

    const fetchIssue = (key = '') => {
        let issueKey = key === '' ? issue.key : key;
        setMode('in-flight');
        return iAxios.get(`/api/v1/jira`, {
            params: {
                path: `/rest/api/3/issue/${issueKey}?expand=renderedFields`
            }
        }).then(resp => {
            setMode('');
            setIssue(resp.data);
        });
    }

    const updateTicket = (e) => {
        e.preventDefault();
        const elements = e.target.elements;
        const component = elements?.component.value;

        const path = `/rest/api/2/issue/${issue.key}`;
        const method = 'PUT';
        setMode('updating');
        const data = {
            fields: {
                components: [{ id: component }],
            }
        };
        iAxios.post('/api/v1/jira', {
            path,
            method,
            data,
            token
        }).then(resp => {
            setMode('');
            return fetchIssue();
        }).catch(ex => {

            setMode('');
            alert(ex.message);
        });
    }

    const runsView = runs.map(run => {
        let runCls = 'ion-play w3-text-yellow';
        if (run.fields.status.name === 'Rejected') {
            runCls = 'ion-close-circled w3-text-red';
        } else if (run.fields.status.name === 'Accepted') {
            runCls = 'ion-checkmark-circled w3-text-green';
        } else if (run.fields.status.name === 'Invalid') {
            runCls = 'ion-close-circled w3-text-yellow';
        } else if (run.id < startId) {
            runCls = 'ion-play w3-text-red';
        }
        return <a target="_blank"
            rel="noreferrer"
            href={getIssueLink(run)} className="w3-padding-small" title={`[${run.fields.status.name}] ${run.key} - ${run.fields.summary}`}>
            <i className={runCls} /></a>
    }).reverse();
    const run = null;
    const pendingRuns = runs.filter(r => r.fields.status.statusCategory.key !== 'done');
    const dislableRun = !sprint || sprint.state === 'closed' || token === '' || version === "" || mode === 'in-flight' || pendingRuns.length > 0;

    return <div className='w3-margin-bottom w3-light-grey w3-padding' >
        <div className="w3-row">
            <div className="w3-col m7">
                {idx + 1}) {issue.key} - <a target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}
                    href={getIssueLink(issue)}>{issue.fields.summary}</a> <br />
                {issue.fields.components.map(item => <span className="w3-small w3-border w3-label w3-padding-small w3-white" style={{ wordBreak: 'break-word' }}>{item.name}</span>)}


            </div>
            <div className="w3-col m2 w3-right-align">
                {runsView} &nbsp;
            </div>
            <div className=" w3-col m3 w3-right-align">
                <span className="w3-small"> {issue.fields?.assignee?.displayName}</span>
                <div className="w3-small">by: {issue.fields.creator.displayName}</div>
                <button className="w3-button  w3-padding-small w3-round" style={{ marginLeft: '2px' }} onClick={e => setStatus(!status)}>
                    <i className={status ? 'ion-arrow-up-b' : 'ion-arrow-down-b'}></i></button>
            </div>
        </div>
        {status && <div className="">
            <div dangerouslySetInnerHTML={{ __html: issue.renderedFields.description }} />

            {issue.fields.components.length === 0 && <form onSubmit={updateTicket}
                className="w3-row w3-padding w3-border w3-margin-bottom">
                <div className="w3-col m6">
                    <label>Component<select name="component" className="w3-input w3-white w3-padding-small">
                        {components.map(item => <option value={item.id}>{item.name}</option>)}
                    </select></label></div>
                <div className="w3-col m6">
                    <button disabled={mode !== ''} className="w3-button w3-blue w3-padding-small w3-right w3-round">
                        {mode === '' ? 'Update' : 'Saving..'}</button>
                </div>
            </form>}
            <form onSubmit={createRun} className="w3-row w3-margin-top w3-white w3-padding">
                <div className="w3-col m8 w3-padding">
                    {run && <textarea className="w3-input w3-border" name="comment"></textarea>}
                    {dislableRun &&
                        <div className="w3-small  w3-align-right w3-left">
                            {pendingRuns.length === 0 && <span className="w3-text-red">Select Sprint,Version and Set your token to create runs</span>}
                            {pendingRuns.length > 0 && <div className="w3-text-orange"> Pending Runs : (Set sprint and version)
                        {pendingRuns.map(i => <a target="_blank" rel="noreferrer" className="w3-block w3-padding-small" href={getIssueLink(i)} >{i.key}</a>)}
                            </div>}
                        </div>}
                </div>
                <div className="w3-col m4">
                    {run && <div>
                        <input type="hidden" name="ticketId" value={run.key} />
                        <div className="w3-padding">
                            <a href={getIssueLink(run)}>
                                {run.key} -  {run.fields.status.name} </a>
                        </div>
                        <select name="transition" className="w3-input w3-border w3-padding-small w3-margin-bottom">
                            {transitions.map(item => {
                                return <option value={item.id}>{item.name}</option>
                            })}
                        </select>
                    </div>}
                    {(issue.fields.issuetype.name !== 'TestCaseRun' && run == null) && <>
                        <div className="w3-row">
                            <button disabled={dislableRun}
                                className="w3-button  w3-round w3-orange w3-padding-small w3-right">Create Run</button>
                        </div>
                        {run && <button disabled={!sprint || mode === 'in-flight'}
                            className="w3-button w3-round w3-green w3-padding-small w3-right">Update Run</button>}
                    </>}
                </div>
            </form>

        </div>}

    </div>
}