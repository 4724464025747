import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { paginatedFetch } from "../../utils";
import IssueGroup from "../perf/IssueGroup";

const { useSelector, useDispatch } = require("react-redux");

export const ClearFilterButton = () => {

    const userList = useSelector(st=> st.filters.userList)
    const dispatch = useDispatch();
    const clearFilter = () => {
        dispatch({
            type: 'filter_users',
            users: [],
        })
    }

    return <span className={`w3-dropdown-hover w3-round w3-margin-left`}>
        <button className={`w3-button w3-round w3-blue `}
            title={`Remove filter ${userList.map(item => item.name).join(',')}`}
            disabled={userList.length == 0}
            onClick={clearFilter}
        >
            {/* <i className='ion-android-funnel w3-text-black'  /> */}
            <i className="ion-ios-people" />
        </button>
        {userList.length > 0 && <div className="w3-dropdown-content w3-bar-block w3-border w3-padding">
            Remove filtering on
            <ol className="w3-list">
                {userList.map((item) => (<li>{item.name}</li>))}
            </ol>
        </div>}
    </span>
}


export const useIssueFetcher = () => {

    const [searchParams] = useSearchParams();
    const [issueState, setIssueState] = useState({
        issueList: [],
        tm: "--",
        issueCount: 'loading',
        userList: null
    });


    const { milestones, userFilter } = useSelector(item => ({
        milesotnes: item.milestones,
        userFilter: item.userFilter ? item.userFilter : []
    }))

    let milestone = searchParams.get('milestone');
    if (!milestone) {
        milestone = 'CurrentSprint';
    }

    let filter = searchParams.get('filter');
    if (!filter) {
        filter = 'all'
    }

    const reloadBtn = useRef(null);

    const processIssues = (list, meta) => {
        const map = list.reduce((acc, item) => {
            let uname = 'unAssigned';
            let name = 'Un Assigned';
            if (item.assignee) {
                uname = item.assignee.username;
                name = item.assignee.name;
            }
            if (!acc[uname]) {
                acc[uname] = new IssueGroup(uname, name);
            }
            acc[uname].addIssue(item);
            return acc;
        }, {});

        setIssueState({
            tm: meta.tm,
            issueCount: list.length,
            issueList: list,
            userList: Object.values(map).sort((a, b) => a.totalEstimate - b.totalEstimate)
        });

        if (reloadBtn?.current) {
            reloadBtn.current.disabled = false;
        }
    }


    const fetchData = (mode = '') => {
        if (reloadBtn?.current) {
            reloadBtn.current.disabled = false;
        }
        setIssueState({
            userList: null,
            tm: '--',
            issueCount: 'loading',
            issueList: [],
        })
        setTimeout(na => {
            paginatedFetch('/api/v1/issues', {
                page: 1,
                mode: mode,
                milestone,
            }).then(resp => {
                processIssues(resp.data, resp.meta);
            }).catch(err => {
                console.error(err);
                alert(`Erro Status: ${err.message}`);
            });
        }, 100);
    }

    useEffect(() => {
        // console.log('trying to fetch :', milestone, filter)

        if (!milestone || !filter) {
            // if (document.location.pathname != '/board/') {
            //     document.location.href = '/board';
            // }
        } else {
            fetchData('normal');
        }
    }, [milestone, milestones])

    console.log('len : ',issueState.issueList.length)

    return {
        issueState,
        filter,
        milestone,
        userFilter,
        fetchData,
        reloadBtn,
    } 
}
