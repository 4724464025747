

import React, { Component, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { calcBurnDown, formatDay, getMilestone } from '../../utils';
import { ButtonGroup } from '../Helper';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const BurnDown = (props) => {

    const [sp] = useSearchParams();
    const [mode, setMode] = useState('Time');

    const formatTick = (lab) => {
        if (mode == 'Time') {
            return formatDay(lab);
        }
        return lab;
    }

    const modeChange = (md) => {
        setMode(md);
    }

    const selectedMilestone = getMilestone(sp);
    const buttons = ['Count', 'Weight', 'Time']; 
    const chartData = calcBurnDown(props.issueList,
        mode.toLowerCase(),
        selectedMilestone
    );

    return <div className="w3-border w3-white w3-responsive">
        <h4 className="w3-center">Sprint BurnDown </h4>
        <ButtonGroup buttons={buttons} selected={mode} onSelect={modeChange.bind(this)} />
        <ResponsiveContainer width='100%' aspect={20.0 / 9.0}>
            <LineChart margin={{ top: 40, left: 40, right: 40, bottom: 40 }} data={chartData}>
                <XAxis dataKey="name" />
                <YAxis tickFormatter={formatTick.bind(this)} />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                <Line type="monotone" name="Open" dataKey="open" stroke="#4CAF50" />
                <Line type="monotone" name="Added" dataKey="added" stroke="#0000d8" />
                <Line type="monotone" strokeDasharray="5 5"
                    activeDot={{ r: 4 }} name="Ideal" dataKey="ideal" stroke="#777777" />
                <Tooltip formatter={formatTick.bind(this)} />
            </LineChart>
        </ResponsiveContainer>
    </div>

}

 export default BurnDown