import React from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import UsersStatus from './UsersStatus';
import { actionClearStorage } from '../../redux/actions';
import { ClearFilterButton } from '../common/components';

const Header = ({
    title,
    leftTitle,
    leftLink,
    leftItem,
    leftInfo,
    user
}) => {
    const userFilter = useSelector(st => st.userFilter)
    return (<div className="w3-theme w3-container w3-row " style={{position:'fixed', top:'0px', zIndex:5, width: 'calc(100% - 240px)'}}>
        <div className="w3-left w3-col m5">
            <h3 className="w3-left" >{title}</h3>
            <h3 className="w3-left" >{leftInfo}</h3>
        </div>
        <div className="w3-right w3-col m7 w3-right-align">
          
            <UsersStatus  user={user} />
            <a className='w3-button w3-black w3-round w3-margin-left' href={`cgs-copilot://login/${localStorage.getItem('accessToken')}`}>Copilot Login</a> 
            <ClearFilterButton userFilter={userFilter} className={'w3-padding w3-left w3-white'} />
            {leftItem}
            {leftLink && <a className="w3-right " href={leftLink} target="_blank">
                <h3 style={{ margin: "0px" }}>{leftTitle}</h3>
            </a>}
        </div>
    </div>);
};
 
export default (Header);