import { combineReducers, bindActionCreators } from "redux";
import moment from 'moment';
function apps(state = { isFetching: false, apps: [] }, action) {
    switch (action.type) {
        case "REQUEST_APPS":
            return Object.assign({}, state, {
                isFetching: true
            });
        case "RECEIVE_APPS":
            return Object.assign({}, state, {
                isFetching: false,
                apps: action.apps
            });
        default:
            return state
    }
}

const milestones = (state = [], action) => {
    if (action.type == 'SET_MILESTONE_LIST') {
        action.milestoneList.sort((a, b) => moment(b.start_date) - moment(a.start_date))
        return action.milestoneList;
    }
    return state;
}


const works = (st = [], action) => {
    return st;
}

const routeParams = (st = {
    milestoneName: 'CurrentSprint',
    milestone: null,
    milestoneList: [],
}, action) => {

    if (action.type == 'SET_MILESTONE_NAME') {
        if (!action.milestoneName) {
            return st;
        }
        let milestone = st.milestoneList.find(m => m.title == action.milestoneName);
        if (!milestone) {
            const parts = action.milestoneName.split('_');
            if (parts.length > 0) {
                milestone = {
                    start: parts[0],
                    end: parts[1],
                    name: action.milestoneName
                }
            }

        }
        return Object.assign({}, st, {
            milestoneName: action.milestoneName,
            milestone
        });
    }
    if (action.type == 'SET_MILESTONE_LIST') {
        const milestone = action.milestoneList.find(m =>
            m.title == st.milestoneName);
        return Object.assign({}, st, {
            milestone,
            milestoneList: action.milestoneList
        });
    }
    return st;
}
const userInfo = (st = null, action) => {
    if (action.type == 'SET_USER_INFO') {
        return action.data;
    }
    return st;
}
const filters = (st = {
    users: [],
    userList: [],
}, action) => {
    if (action.type == 'filter_users') {
        return Object.assign({}, st, {
            users: action.users.map(item => item.uname),
            userList: action.users,

        });
    }
    return st;
}
export default combineReducers({
    userInfo,
    works,
    filters,
    routeParams,
    apps,
    milestones
});