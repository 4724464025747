
import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, createBrowserRouter, Route, Router, RouterProvider, Link, Outlet, useSearchParams, NavLink, useNavigate } from 'react-router-dom'
import Status, { Financials, TeamActivity } from './pages/Status';
import Member from './pages/Member';
import Project from './pages/Project';
import Client from './pages/Client';
import Login, { LoginHandler } from './pages/Login';
import Room from './pages/Room';
import { Jira } from './perf/Jira';
import { useDispatch, useSelector } from 'react-redux';
import { Leaves } from './perf/Leaves';
import IssueBoard from './widgets/IssueBoard';
import Dashboard from './pages/Dashboard';
import { UserActivity } from './pages/Member';
import Header from './widgets/Header';
import { actionClearStorage, fetchMilestones, fetchUserInfo } from '../redux/actions';
import { MilestoneSwitch } from './perf/MilestoneSwitch';
import { ProjectContrib } from './widgets/ProjectContrib';


const SidebarLink = (props) => {
    return <NavLink className="w3-bar-item w3-button w3-padding w3-big" {...props} style={({ isActive }) => ({
        color: isActive ? '#000' : '#888',
    })}
    >{props.children}</NavLink>
}
function NotFound() {
    return <div className='w3-center w3-padding-64'>
        <h1>404 - Page Not Found

        <Link to="/">Go home</Link>
    </h1>
    </div>;
}

const Layout = () => {
    const [sp] = useSearchParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { userInfo, milestones } = useSelector(st => ({
        userInfo: st.userInfo,
        milestones: st.milestones
    }));
    console.log(userInfo)
    useEffect(() => {
        if (userInfo) {
            return;
        }
        dispatch(fetchUserInfo()).then(resp => {
            const data = resp.data.data;
            localStorage.setItem('user', JSON.stringify(data));
            if (document.location.pathname == '/' || document.location.pathname == '/login') {
                navigate('/dashboard')
            }
        }).catch(ex => {
            document.location.href = "/login";
        });
    }, [])

    useEffect(() => {
        if (!userInfo) {
            return;
        }
        if (milestones.length) {
            return
        }
        if (!milestones || milestones.length == 0) {
            dispatch(fetchMilestones()).then((resp) => {
                console.log('data', resp.data);
                // const info = JSON.parse(userData);
            })
        }
    }, [userInfo])

    const logout = () => {
        if (!window.confirm('Are you sure you want to logout')) {
            return;
        }
        actionClearStorage();
        document.location.href = "/";
    }
    const qs = sp.toString();

    return <div className="w3-light-grey " >

        <nav className="w3-sidebar w3-collapse w3-white  w3-top" style={{ zIndex: 3, width: "240px" }} ><br />
            <div className=" w3-center">
                <img src='/logo128.png' />
                <a href="/" className="w3-hide-large w3-right w3-jumbo w3-padding w3-hover-grey" title="close menu">
                    <i className="ion-navicon-round" style={{ color: 'black' }}></i>
                </a>
            </div>
            <div className="w3-bar-block">
                <SidebarLink to={`/dashboard?${qs}`} > Team Dashboard </SidebarLink>
                <SidebarLink to={`/my-team?${qs}`} > Team </SidebarLink>
                <SidebarLink to={`/my-day?${qs}`} >  My Day</SidebarLink>
                <SidebarLink to={`/team-status?${qs}`} >  Team Status </SidebarLink>
                <SidebarLink to={`/member?${qs}`} > My Tickets </SidebarLink>
                <SidebarLink to={`/board?${qs}`} > Kanban Board </SidebarLink>
                <SidebarLink to={`/contrib?${qs}`} > Contributions</SidebarLink>
                <SidebarLink to={`/leaves?${qs}`} > Leaves </SidebarLink>
                <SidebarLink to={`/utilisation?${qs}`} > Utilisation</SidebarLink>
                <button onClick={logout} className='w3-button w3-block w3-margin-top w3-left-align'> Logout </button>
            </div>

        </nav>

        <div className="w3-overlay w3-hide-large w3-animate-opacity"
            style={{ cursor: "pointer" }} title="close side menu" id="myOverlay"></div>

        <div className="w3-main" style={{ marginLeft: "240px" }}>
            <Header user={userInfo} title="Zcalo Dashboard" leftItem={<MilestoneSwitch />} />
            <div className="w3-padding" style={{ overflow: 'scroll', minHeight: 'calc(100vh - 90px)', marginTop: '60px' }}>
                <Outlet />
            </div>
            <div className="w3-black w3-center w3-padding">Powered by <a href="https://zcalo.ai"
                title="zcalo.ai" target="_blank" className="w3-hover-opacity">Zcalo.ai</a></div>
        </div>
    </div>
}


const router = createBrowserRouter([

    {
        path: "/login/:provider",
        element: <Login />,
    },
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: "/",
        element: <Layout />,
        children: [
            {

                path: "/jira/:projectId/:componentId?/:page?",
                element: <Jira />
            },
            {
                path: "/leaves",
                element: <Leaves />
            },
            {
                path: "/dashboard",
                element: <Dashboard />
            },
            {

                path: "/room/:id?/:mode?",
                element: <Room />
            },
            {

                path: "/project/",
                element: <Project />
            },
            {

                path: "/client/",
                element: <Client />
            },
            {

                path: "/member/:username?",
                element: <Member />
            },
            {
                path: "/contrib",
                element: <ProjectContrib />
            },
            {

                path: "/my-team/",
                element: <TeamActivity />
            },
            {

                path: "/my-day/",
                element: <UserActivity />
            },
            {
                path: "/utilisation",
                element: <Financials />
            },
            {

                path: "/my-tickets",
                element: <Member />
            },
            {
                path: '/team-status',
                element: <Status />
            },
            {
                path: 'board',
                element: <IssueBoard />
            },
            {

                path: '/copilot-login',
                element: <LoginHandler />
            },
        ],
    },
    {
        path: "*",
        element: <NotFound />
    }


]);

const App = (props) => {
    return (<RouterProvider router={router} />)

}


export default App
