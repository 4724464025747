import React, { useEffect, Component, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { formatSecs, COLORS, iAxios, roundUpHour, getDiffClass, getIssueStats, getMilestone } from '../../../utils';
import BurnDown from '../../perf/BurnDown';
import { SprintBacklog, TodaysProgress } from '../../widgets/SprintBacklog';
import { connect, useSelector } from 'react-redux';

import Leads, { BlogStatus } from './Leads';
import ProductBacklog from './ProductBacklog';
import MemberStats from './MemberStats';
import { useIssueFetcher } from '../../common/components';

const Dashboard = () => {

    const { issueState: { issueList, userList },  } = useIssueFetcher(); 
    console.log(userList)
    const milestone = getMilestone();
    const colorMap = {};
    const {
        sprintStatus,
        completionStatus,
        tmTotal,
        prog,
        progVal
    } = getIssueStats(issueList, milestone);


    if (!issueList) {
        return <div>Loading</div>
    }
    if (issueList.length === 0) {
        return <div>Milestone have Zero issues</div>
    }

    return <div>
        <div className="w3-row-padding  w3-margin-top">
            <div className="w3-col l4 m5 s12 w3-margin-bottom">
                <Leads userList={userList} milestone={milestone.title} />
                <SprintBacklog sprintStatus={sprintStatus}
                        prog={prog}
                        milestone={milestone.title}
                        ASSIGNMENTS={colorMap}
                        completionStatus={completionStatus} />
                        
            </div>
            <div className="w3-col l5 m7 s12">
                <div className="w3-margin-bottom">
                    <MemberStats userList={userList}
                        milestone={milestone}
                        tmTotal={tmTotal}
                        prog={prog}
                        progVal={progVal} />
                </div>
                <div className="w3-margin-bottom" >
                    <BurnDown issueList={issueList} />
                </div>
                <div className="w3-margin-bottom" >
                <ProductBacklog
                        ASSIGNMENTS={colorMap} />
                </div>
            </div>
            <div className="w3-col w3-row-padding l3 m12  s12">
                <div className="w3-col l12 m6 w3-margin-bottom">
                    <TodaysProgress />
                </div>
                <div className="w3-col l12 m6 w3-margin-bottom">
                   
                <BlogStatus userList={userList} />
                </div> 

            </div>
        </div>
    </div>
}

const mapSt2Pr = (state) => {
    return {
        milestoneList: state.milestones,
        routeParams: state.routeParams
    }
}
export default connect(mapSt2Pr)(Dashboard)
