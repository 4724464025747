/*  eslint indent : "off" , react/jsx-indent: "off"
*/
import React, {
    Component, useState
} from 'react';
import { useSelector } from 'react-redux';
import * as moment from 'moment';
import {
    filterIssues,
    timeStats,
    formatDay,
    getIssueProject,
    getMilestoneName
} from '../../utils';
import { useIssueFetcher } from '../common/components';

const IssueCard = ({
    issue
}) => {
    let cls = issue.state === 'closed' ? 'cgs-border-top-green' : '';
    if (issue.timeEstimate === null) {
        cls = 'cgs-border-top-red';
    }

    return (
        <div className={`${cls} w3-card w3-round  w3-margin-bottom w3-padding-small`}>
            <div><a style={{ textDecoration: "none" }} href={issue.url} rel="noreferrer" target="_blank">{issue.title}</a></div>
            <div>
                <span className="cgs-small-tag w3-blue">{issue.assignee && issue.assignee.name}</span>
                {issue.labels.map(l => <span key={l} className="cgs-small-tag w3-yellow">{l}</span>)}
                <span className="cgs-small-tag w3-green">
                    {timeStats(issue)}
                </span>
                <span className='cgs-small-tag w3-purple'>
                    {moment(issue.dueDate).format('MMM DD')}
                </span>
            </div>
        </div>
    )
}
const IssueBoard = (props) => {
    const {issueState,userFilter,filter,milestone,reloadBtn,fetchData} = useIssueFetcher()

    console.log('board ',issueState.issueList);
    let  issues = issueState.issueList;

    const [swimMode, setSwimMode] = useState('project');
    const [showCards, setShowCards] = useState(true);


    const modeChange = (e) => {
        setSwimMode(e.target.value);
    }

    const changeCardView = (e) => {
        setShowCards(e.target.checked);
    }
 

    let milestoneName = getMilestoneName();

    const filters = [
        "Backlog",
        "Dev",
        "Worked",
        "QA",
        "Ops",
        "Completed"
    ];

    let swimLanes = { 'Team': issues };

    if (swimMode && swimMode !== 'team') { 
        swimLanes = issues.reduce((acc, issue) => {
            if (userFilter.length > 0) {
                if (!userFilter.some(uname => uname == issue.assignee.username)) {
                    return acc;
                }
            }
            let name = 'UnAssigned';
            if (swimMode === 'project') {
                name = getIssueProject(issue)
            } else if (swimMode === 'user') {
                if (issue.assignee) {
                    name = issue.assignee.name;
                }
            }
            if (!acc[name]) {
                acc[name] = [];
            }
            acc[name].push(issue);
            return acc;
        }, {});
    }

    const headers = [];
    let rows = [];
    let completedCount = 0;
    Object.keys(swimLanes).forEach(name => {
        const cols = []
        let rowTotal = 0;
        let rowSpentTotal = 0;
        const list = filters.map((title, idx) => {
            const list = filterIssues(swimLanes[name], title.toLowerCase());

            let label = <span className="w3-small">{title}</span>;
            const totalTm = list.reduce((acc, i) => acc + i.timeEstimate, 0);
            const totalSpend = list.reduce((acc, i) => acc + i.timeSpent, 0);
            if (title.toLowerCase() === 'completed') {
                completedCount = list.length;
            }

            rowTotal += totalTm;
            rowSpentTotal += totalSpend;
            cols.push(<td key={`col_${idx}`} style={{ padding: "2px 8px" }} className="cgs-kanban-col">{label}
                <span className="cgs-small-tag w3-right w3-blue">{list.length}</span>
                <span className="cgs-small-tag w3-right w3-blue-grey">{formatDay(totalTm)}</span>
            </td>);
            return <td key={`col_${idx}`} className="cgs-kanban-col">
                {list.map(i => <IssueCard key={`card_${i.id}`} issue={i} />)}
            </td>
        });

        let url = '';
        if (swimMode === 'project') {
            url = `https://gitlab.ceegees.in/ceegees/${name.replace('.', '/')}/boards?scope=all&state=opened&milestone_title=${milestoneName}`;
        } else {
            url = `https://gitlab.ceegees.in/groups/ceegees/-/boards?scope=all&state=opened&milestone_title=${milestoneName}`;
        }
        const nList = []
        nList.push(<tr className={completedCount === swimLanes[name].length ? 'w3-green' : "w3-theme-l4 w3-border-bottom"} key={name}>
            <td style={{ padding: "4px 8px" }} colSpan={filters.length}>
                <a className="" style={{ textDecoration: 'none', textTransform: 'capitalize' }}
                    target="_blank" href={url}>{name} </a>
                {/* <span className="cgs-small-tag w3-right w3-white">Velocity: {formatDay(rowSpentTotal / completedCount)}</span> */}
                <span className="cgs-small-tag w3-right w3-white w3-small">S: {formatDay(rowSpentTotal)} / E: {formatDay(rowTotal)}</span>
               
                <span className="cgs-small-tag w3-right w3-white w3-small">Tasks: {completedCount}/{swimLanes[name].length}</span>
            </td>
        </tr>);
        nList.push(<tr className="w3-light-grey" key={name + "_times"}>{cols}</tr>);
        if (showCards) {
            nList.push(<tr key={name + '_issues'}>{list}</tr>);
        }
        if (completedCount === swimLanes[name].length) {
            rows = rows.concat(nList);
        } else {
            rows = nList.concat(rows);
        }
    });

    return <div className="w3-responsive">
        <div>
            <div className="w3-padding-small w3-col m4">
                <select className="w3-select" onChange={modeChange}>
                    <option value="project">Project</option>
                    <option value="user">User</option>
                    <option value='team'>Team</option>
                </select>
            </div>
            <div className="w3-col m4 w3-right-align w3-right w3-padding">
                <label className='w3-margin-right w3-margin-top' style={{ lineHeight: "32px" }}>
                    <input className="w3-checkbox" checked={showCards}
                        onChange={changeCardView} type="checkbox"></input> Show Ticket Cards
                </label>
            </div>
        </div>
        <table className="w3-table w3-border" style={{minWidth:'1024px'}}>
            <thead>
                <tr>{headers}</tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table> 
    </div>

}


export default IssueBoard;