import React, { Component } from 'react'

import { Spinner, TRWrapper } from '../Helper';
import { Link } from 'react-router-dom';
import { timeStats, formatSecs } from '../../utils'
import moment from 'moment';
export default class IssuesTable extends Component {
    exportCSV() {
        const list = this.props.issues.map((item, idx) => {
            return [
                idx + 1,
                "\"" + item.title + "\"",
                item.timeSpent === null ? "NA" :
                    formatSecs(item.timeSpent),
                item.timeEstimate === null ? 'NA' :
                    formatSecs(item.timeEstimate),
                item.created_at ? moment(item.created_at).format('YYYY-MM-DD') : '',
                item.closed_at ? moment(item.closed_at).format('YYYY-MM-DD') : '',
                item.assignee ? item.assignee.name : 'Not Assigned',
                item.state
            ].join(',')
        });
        const headers = ["#", "Title", "Time Spent", "Time Estimated", "Created", "Closed", "Assignee", "Status"];
        const csv = "data:text/csv;charset=utf-8," + headers.join(",") + "\r\n" + list.join("\r\n");
        var encodedUri = encodeURI(csv);
        window.open(encodedUri);
    }
    render() {
        let content = null;
        let list = [];
        const { error, issues, tagStatus, sprintName } = this.props;
        if (error) {
            list.push(
                <TRWrapper colSpan="5" key="error">
                    <div className="w3-red w3-padding w3-center">
                        <h6>{error}</h6>
                    </div>
                </TRWrapper>);
        } else if (issues && issues.length == 0) {
            list.push(<TRWrapper colSpan="5" key="message">
                <div className="w3-padding w3-center">
                    <h6>No issues to list</h6>
                </div>
            </TRWrapper>);
        } else if (issues) {
            list = issues.map((item, idx) => {
                return <tr key={item.id} className={item.state == 'closed' ? 'w3-green' :
                    (item.timeSpent > 0 ? 'w3-pale-green' : 'w3-light-grey')}>
                    <td>{idx + 1}</td>
                    <td>
                        <a href={item.url} style={{ textDecoration: 'none' }} target="_blank" >{item.title}</a>
                        {item.labels.map(l => <span key={l} className="cgs-muted w3-tag 
                        w3-yellow">{l}</span>)}
                    </td>
                    {/* <td>{moment(item.created_at).format('YYYY-MM-DD') }</td> */}
                    <td>{item.assignee ? <Link to={`/member/${item.assignee.username}/${sprintName}`}>{item.assignee.name}</Link> : 'Not Assigned'} </td>
                    <td>
                        {timeStats(item)}
                    </td>
                </tr>
            });
        } else {
            list.push(<TRWrapper colSpan="5" key="loading">
                <Spinner />
            </TRWrapper>);
        }

        return <div className="w3-responsive w3-border">
            <table className="w3-table ">
                <thead>
                    <tr>
                        <th>#</th>
                        <th><div>Issues
                        <button className="w3-button w3-right w3-tiny" onClick={this.exportCSV.bind(this)}>Export</button>
                        </div>

                            <span className="cgs-muted">{
                                Object.keys(tagStatus).join(', ')
                            }</span>
                        </th>
                        <th style={{ whitespace: "nowrap" }}>Assignee</th>
                        <th style={{ whitespace: "nowrap" }}>Time Status
                        <br /><span className="cgs-muted">spent/estimate</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {list}
                </tbody>
            </table>
        </div>

    }
}