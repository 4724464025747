import { useEffect, useState } from "react";
import { iAxios } from '../../utils';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Spinner } from "../Helper";


const CLRS = {
    'ACCEPTED': '#32a852',
    'IN PROGRESS.': '#f7f302',
    'INVALID': '#e3e317',
    'REJECTED': '#eb0c0c',
    'TO DO': '#eb9c34'
}



const CheckboxItem = ({ el, onChange }) => {
    return <label className="w3-left block-margin" style={{ cursor: 'pointer' }} onClick={e => onChange(!el.selected, el)}>
        <span className="w3-label w3-padding-small w3-inline-block w3-border w3-round " >
            <i className={` ${el.selected ? 'ion-android-checkbox-outline ' : 'w3-text-black  ion-android-checkbox-outline-blank'}`}
                style={{ marginRight: "4px", fontSize: '18px' }} />
            <span >{el.name}</span>
        </span>
    </label>
}


const getRunStatus = async (projectId, sprintId) => {

    const jql = `project=${projectId} AND SPRINT=${sprintId} AND issueType=TestCaseRun and issueLinkType is not empty ORDER BY updated DESC`;
    let page = 0;
    let issueList = [];
    while (true) {
        const result = await iAxios.get('/api/v1/jira', {
            params: {
                path: `/rest/api/3/search?startAt=${page * 50}&jql=${encodeURIComponent(jql)}`
            }
        });
        page++;
        if (result.data) {
            issueList = issueList.concat(result.data.issues);
            if (result.data.issues.length < 50) {
                break;
            }
        }
    }
    return issueList;
}

export const StatusWidget = ({ projectId, sprintId, closePopup }) => {

    const [list, setList] = useState([]);
    const [mode, setMode] = useState('');
    const [versions, setVersions] = useState([]);
    const [labels, setLabels] = useState([]);
    const [components, setComponents] = useState([]);
    const [assignees, setAssignees] = useState([]);
    const [data, setData] = useState([]);

    const [totalCount, setTotal] = useState(0);
    // const [jql, se/tJql] = useState()


    useEffect(() => {

        const iLabels = [];
        const iComponents = [];
        const iVersions = [];
        const iAssignees = [];

        const iData = list.reduce((agg, ri) => {
            const item = agg.find(a => a.name === ri.fields.status.name);

            ri.fields.labels.forEach(lab => {
                if (!iLabels.find(l => l.id === lab)) {
                    iLabels.push({
                        id: lab,
                        name: lab,
                        selected: true
                    });
                }
            });

            ri.fields.fixVersions.forEach(ver => {
                if (!iVersions.find(v => v.id === ver.id)) {
                    iVersions.push({
                        id: ver.id,
                        name: ver.name,
                        selected: true
                    });
                }
            });

            ri.fields.components.forEach(comp => {
                if (!iComponents.find(c => c.id === comp.id)) {
                    iComponents.push({
                        id: comp.id,
                        name: comp.name,
                        selected: true
                    });
                }
            });



            if (ri.fields.assignee && !iAssignees.find(a => a.id === ri.fields?.assignee?.accountId)) {
                iAssignees.push({
                    id: ri.fields.assignee.accountId,
                    selected: true,
                    name: ri.fields.assignee.displayName
                })
            }




            if (!item) {
                agg.push({
                    name: ri.fields.status.name,
                    value: 1
                })
            } else {
                item.value += 1;
            }
            return agg;
        }, []);

        iVersions.push({
            id: 'empty',
            name: 'EMPTY',
            selected: true,
        });
        iComponents.push({
            id: 'empty',
            name: 'EMPTY',
            selected: true,
        });
        iLabels.push({
            id: 'empty',
            selected: true,
            name: 'EMPTY'
        });
        iAssignees.push({
            id: 'empty',
            selected: true,
            name: 'None'
        })

        setAssignees(iAssignees);
        setData(iData);
        setVersions(iVersions);
        setComponents(iComponents);
        setLabels(iLabels)
    }, [list]);

    useEffect(() => {
        let total = 0;

        const iData = list.filter(item => {
            const fields = item.fields;
            const flag1 = labels.filter(l => l.selected).some(l => {

                if (l.id === 'empty') {
                    return fields.labels.length === 0;
                }
                return fields.labels.some(lab => lab === l.name);
            });

            const flag2 = versions.filter(v => v.selected).some(v => {
                if (v.id === 'empty') {
                    return fields.fixVersions.length === 0;
                }
                return fields.fixVersions.some(va => v.id === va.id)
            });
            const flag3 = components.filter(c => c.selected).some(c => {
                if (c.id === 'empty') {
                    return fields.components.length === 0;
                }
                return fields.components.some(comp => {
                    return c.id === comp.id
                });
            });

            const flag4 = assignees.filter(a => a.selected).some(a => {
                if (a.id === 'empty') {
                    return !fields.assignee;
                }
                if (!fields.assignee) {
                    return false;
                }
                return fields.assignee.accountId === a.id;
            });
            return flag1 && flag2 && flag3 && flag4;
        }).reduce((agg, ri) => {
            const item = agg.find(a => a.name === ri.fields.status.name);
            total++;
            if (!item) {
                agg.push({
                    name: ri.fields.status.name,
                    value: 1
                })
            } else {
                item.value += 1;
            }
            return agg;
        }, []);
        setTotal(total);
        setData(iData.sort((a, b) => a.name.localeCompare(b.name)));

    }, [labels, components, versions, assignees, list])

    useEffect(() => {
        const process = async () => {
            setMode('loading')
            const status = await getRunStatus(projectId, sprintId);
            setList(status);
            setMode('loaded')
        }
        process();
    }, [projectId, sprintId])

    const nameMap = name => {
        return name === 'EMPTY' ? name : `"${name}"`;
    }
    const getUrl = (status) => {
        //`project = ${projectId} AND Sprint = ${sprintId} ORDER BY created DESC`
        const jql = `project = ${projectId} AND Sprint = ${sprintId} AND  \
         fixVersion in (${versions.filter(item => item.selected).map(v => nameMap(v.name)).join(',')}) AND \
         component in (${components.filter(item => item.selected).map(c => nameMap(c.name)).join(',')}) AND \
         assignee in (${assignees.filter(item => item.selected).map(a => a.id)}) AND \
         labels in (${labels.filter(item => item.selected).map(l => nameMap(l.name)).join(',')}) and status=${nameMap(status)} ORDER BY created DESC`;

        return `https://ntuclink.atlassian.net/issues/?jql=${encodeURIComponent(jql)}`;
    }

    const changeLabel = (val, el) => {
        el.selected = val;
        setLabels(labels.concat([]));
    }

    const changeVersion = (val, el) => {
        el.selected = val;
        setVersions(versions.concat([]));
    }
    const changeComp = (val, el) => {
        el.selected = val;
        setComponents(components.concat([]));
    }
    const changeAssignee = (val, el) => {
        el.selected = val;
        setAssignees(assignees.concat([]));
    }

    const toggleComps = () => {
        const next = !components[0].selected
        setComponents(components.map(item => {
            item.selected = next;
            return item;
        }));
    }

    const toggleLabels = () => {
        const next = !labels[0].selected
        setLabels(labels.map(item => {
            item.selected = next
            return item;
        }));
    }
    const toggleVersions = () => {
        const next = !versions[0].selected;
        setVersions(versions.map(item => {
            item.selected = next;
            return item;
        }));
    }

    const toggleAssignee = () => {
        const next = !assignees[0].selected;
        setAssignees(assignees.map(item => {
            item.selected = next;
            return item;
        }));
    }
    const charts = [
        <Pie
            key="outer"
            data={data}
            innerRadius="40%"
            outerRadius="70%"
            dataKey="value"
            cy="50%"
            cx="50%" >
            {data.map((entry, index) => {
                const key = entry.name.toUpperCase();
                const clr = CLRS[key];
                return <Cell key={entry.name} fill={clr} />
            })
            }
        </Pie>,

        <Tooltip key="tooltip" />,
        <Legend iconSize={10} width={120} height={140} layout='vertical' verticalAlign='middle' align="right" />
    ];

    return <div className="w3-modal w3-show">
        <div className="w3-modal-content">
            <div className="w3-padding w3-center w3-large">Test Run Status {list.length > 0 && <span>Total {list.length}</span>}</div>
            <div className="w3-container">
                <span className="w3-button w3-display-topright" onClick={closePopup}>&times;</span>

                <div className="w3-row w3-section">
                    <div className="w3-col m7">
                        {mode === 'loaded' && <ResponsiveContainer width="100%" height={400}>
                            <PieChart >
                                {charts}
                            </PieChart>
                        </ResponsiveContainer>}
                        {mode === 'loading' && <Spinner />}
                    </div>
                    <div className="w3-col m5 w3-padding-64">
                        <table className="w3-table w3-table-all">
                            <tbody>
                                {data.map(item => {
                                    return <tr key={item.name}><td><a target="_blank" rel="noreferrer" href={getUrl(item.name)}>{item.name}</a></td>
                                        <td>{item.value}</td></tr>
                                })}
                                {totalCount > 0 && <tr><td>Total</td><td>{totalCount}</td></tr>}
                            </tbody>
                        </table>
                    </div>
                    {mode === 'loaded' && <div className="w3-row">
                        <div className="w3-col m4">
                            <a href="#" className="w3-large" onClick={toggleLabels}>Labels</a>
                            <div className="w3-row">
                                {labels.map(item => <CheckboxItem el={item} onChange={changeLabel} />)}
                            </div>
                            <a href="#" className="w3-large" onClick={toggleVersions}>Versions</a>
                            <div className="w3-row">
                                {versions.map(item => <CheckboxItem el={item} onChange={changeVersion} />)}
                            </div>
                        </div>

                        <div className="w3-col m8">
                            <a href="#" className="w3-large" onClick={toggleAssignee} >Assignees</a>
                            <div className="w3-row">
                                {assignees.map(item => <CheckboxItem el={item} onChange={changeAssignee} />)}
                            </div>
                            <a href="#" className="w3-large" onClick={toggleComps}> Components</a>
                            <div className="w3-row">
                                {components.map(item => <CheckboxItem el={item} onChange={changeComp} />)}
                            </div>

                        </div>
                    </div>}
                </div>
            </div>
        </div>
    </div>
}